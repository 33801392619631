import React from 'react';
import useFooter from '../../pages/common/useFooter';
const Footer =()=>{
    const [datas,errors] = useFooter();
    //     const duplicateData = `<div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 bg-info">
//     <h4 className="footer-title"> Address : </h4>
//     <p className="footer-content">Sree Ragu Conttracts,</p>
//     <p className="footer-content"> #1, III Floor, Laakini Towers,</p>
//     <p className="footer-content"> Vasuki Ist Street, Near SRC Hospital,</p>
//     <p className="footer-content"> Brough Road,Erode – 638011.</p>
//     <h4 className="footer-title"> Phone : </h4>
//     <p className="footer-content"> 0424 222 0424</p>
// </div>
// <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bg-info" >
//     <h4 className="footer-title"> Mobile : </h4>
//     <p className="footer-content">(+91) 99422 88025,</p>
//     <p className="footer-content">(+91) 96883 59898,</p>
//     <p className="footer-content">(+91) 80729 95035.</p>                    
//     <h4 className="footer-title"> E-mail : </h4>
//     <p className="footer-content">srcragu@gmail.com</p>       
// </div>`;

    const footerList = datas.length && datas.map((data,index)=>{       
        return( 
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 bg-info" key={ index } >
                <h4 className="footer-title"> Address : </h4>
                { data.Address.split("<br />").map((i,key) => {
                                    return <p className="footer-content" key={key}>{i} </p>;
                }) }       
              { data?.TelePhoneNo != "" &&  data?.TelePhoneNo != "null" && <><h4 className="footer-title"> Phone : </h4>
                <p className="footer-content"> { data?.TelePhoneNo } </p></>}
            </div>     
    )
    }) 
    
    const footerContactList = datas.map((data,index)=>{
        return(   <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bg-info" key={ index }  >
        <h4 className="footer-title"> Mobile : </h4>
        { data.MobileNo.split("<br />").map((i,key) => {
                                return <p className="footer-content" key={key}>{i} </p>;
            }) }                               
        <h4 className="footer-title"> E-mail : </h4>
        <p className="footer-content">{ data.EmailId }</p>       
    </div>)
    })


    return(<div>
          <div className="container-fluid">
                    <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 bg-info">
                    </div>   
                    {/* {
                        footerDatas ? <DynamicFooter footerDatas = {footerDatas}  /> : duplicateData
                    } */}
                    {
                        footerList
                    }
                    {
                        footerContactList
                    }
                  
                  
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="well">
                         <p className="footer-align-center">©Sree Ragu Conttracts {(new Date().getFullYear())}  All Rights Reserved. Designed  Developed by karthi</p>
                    </div>
                    </div>     
                </div>
            </div>
    </div>)



}
export default React.memo(Footer)