import React,{useState,useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { MDBDataTable, Row, Col, Card, CardBody } from 'mdbreact';
import { useScroll } from '../../common/useScroll';
import { webConfig,config } from '../../../url/CommonUrl';
import Loader from '../../loader/Loader';



const ScrollPageView = ()=>{

    const history = useNavigate();

    const [datas,setDatas] = useState([]);
    const [errors,setErrors] = useState([]);

    var i =0;

    const [galleryView, galleryUpdate ] = useScroll();   
    

    const changeActiveStatus = (status,RfId)=>{

     const  formData={
        "action" : "Delete",
        "RfId" : RfId,
        "IsActive" : status
      }

      axios.post(`${ webConfig }/scrollGallery/scroll`,formData,config).then((response)=>{

          setDatas(response.data);         

      }).catch((errMsg)=>{
          setErrors(errMsg);
         // console.log(errMsg);
      })

    }
     const data = {
        columns: [
            {
              label:'#',
              field:'id',
            },           
            {
              label:'Images',
              field:'Images',
            },          
            {
                label:'Insert DataTime',
                field:'InsertedDateTime',
            },
            {
                label:'Status',
                field:'IsActive',
            },
           {
              label:'Delete',
              field:'action',
            },
          ],
          rows:galleryView.map((user) => {
              i++;
            return (    
               {
                
                 id: i,
    
                 Images:  <img src={`https://www.sreeragu.com/survey/${ user.Images }`} className="img-thumbnail"  alt="Src" height="100" width="100" />,
    
                 InsertedDateTime: user.InsertedDateTime,
    
                 IsActive: user.IsActive ? 'Active' : 'IsActive',              

                action : [
                   <button type="submit" className="btn btn-primary" onClick={ ()=>changeActiveStatus(user.IsActive ? 0 : 1 , user.RfId ) } >{`${user.IsActive ? 'Delete' : 'Active'} `} </button> , "  " ,
                  //  <button type="submit" className="btn btn-primary" onClick={ ()=>changeUpdateStatus(user.RfId,`https://www.sreeragu.com/survey/${ user.Images }`) }  >Update</button>
                ],                
             }    
             )    
           }),
        }
 
     return (
     
        <div className="container">
              <Loader active={ galleryView.length ? false : true } />
            
        <Row className="mb-4">
  
  <Col md="12">

            <Card>
            
            <CardBody>
            <Col md="10">
            </Col>
            <Col md="2">
            </Col>
                <MDBDataTable

                striped

                bordered

                hover

                data={data}

                />

            </CardBody>

            </Card>

        </Col>

        </Row>       
       </div>
      )
   
}
export default ScrollPageView