import axios from 'axios';

import { GETWEBFOOTERSUCCESS,GETWEBFOOTERFAIL,UPDATEWEBFOOTERSUCCESS,UPDATEWEBFOOTERFAIL } from '../type/Type';

export const webFooterInsert = (formData)=>async dispatch=>{

    const config = {
        headers:{
            'Content-Type' : 'application/json'
        }
    }

    const body = JSON.stringify(formData);

    axios.post("https://www.sreeragu.com/survey/api/webfooter",body,config).then((response)=>{

        dispatch({
            type : UPDATEWEBFOOTERSUCCESS,
            payload : response
        })

    }).catch((errMsg)=>{        

        dispatch({
            type : UPDATEWEBFOOTERFAIL,
            error : errMsg
        })
    })
}

export const getWebFooter = ()=>async dispatch=>{


    const config ={
        headers :{
            'Content-Type' : 'application/json'
        }
    }
    const formData={
        "action" : "View"
    }

    try{

        var response = await axios.post("https://www.sreeragu.com/survey/api/webfooter",formData,config);

        console.log(response);

               dispatch({
                    type : GETWEBFOOTERSUCCESS,
                    payload : response.data
                })

    }catch(err){

        dispatch({
                    type : GETWEBFOOTERFAIL,
                    error : err
                })

    }

    // axios.post("https://www.sreeragu.com/survey/api/webfooter",formData,config).then((response)=>{      
    //     dispatch({
    //         type : GETWEBFOOTERSUCCESS,
    //         payload : response.data
    //     })
    // }).catch((errMsg)=>{      
    //     dispatch({
    //         type : GETWEBFOOTERFAIL,
    //         error : errMsg
    //     })
    // })
}

