import React from 'react';

import RoadDesignImage from '../../asset/image/category/RoadDesign.jpg';

import InnerImage from '../InnerImage';

const RoadDesign = ()=>{

    return(<div>
        <InnerImage name="Road Design" />  
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                        <h4 className="body-heading-title"> Road Design </h4><br />
                                            <p className="body-heading-paragraph" >The geometric design of roads is the branch of highway engineering concerned with the positioning of the physical elements of the roadway according to standards and constraints. The basic objectives in geometric design are to optimize efficiency and safety while minimizing cost and environmental damage. Geometric design also affects an emerging fifth objective called "livability," which is defined as designing roads to foster broader community goals, including providing access to employment, schools, businesses and residences, accommodate a range of travel modes such as walking, bicycling, transit, and automobiles, and minimizing fuel use, emissions and environmental damage. Geometric roadway design can be broken into three main parts: alignment, profile, and cross-section. Combined, they provide a three-dimensional layout for a roadway. The alignment is the route of the road, defined as a series of horizontal tangents and curves. The profile is the vertical aspect of the road, including crest and sag curves, and the straight grade lines connecting them. The cross section shows the position and number of vehicle and bicycle lanes and sidewalks, along with their cross slope or banking. Cross sections also show drainage features, pavement structure and other items outside the category of geometric design.
                                                The profile of a road consists of road slopes, called grades, connected by parabolic vertical curves. Vertical curves are used to provide a gradual change from one road slope to another, so that vehicles may smoothly navigate grade changes as they travel. Sag vertical curves are those that have a tangent slope at the end of the curve that is higher than that of the beginning of the curve. When driving on a road, a sag curve would appear as a valley, with the vehicle first going downhill before reaching the bottom of the curve and continuing uphill or level. Crest vertical curves are those that have a tangent slope at the end of the curve that is lower than that of the beginning of the curve. When driving on a crest curve, the road appears as a hill, with the vehicle first going uphill before reaching the top of the curve and continuing downhill. The profile also affects road drainage. Very flat roads and sag curves may have poor drainage, and steep roads have high velocity flows.
                                            </p>
                                            <h4 className="body-heading-title"> Alignment </h4><br />
                                            <p className="body-heading-paragraph" >Horizontal alignment in road design consists of straight sections of road, known as tangents, connected by circular horizontal curves.[2] Circular curves are defined by radius (tightness) and deflection angle (extent). The design of a horizontal curve entails the determination of a minimum radius (based on speed limit), curve length, and objects obstructing the view of the driver. Using AASHTO standards, an engineer works to design a road that is safe and comfortable. If a horizontal curve has a high speed and a small radius, an increased super elevation (bank) is needed in order to assure safety. If there is an object obstructing the view around a corner or curve, the engineer must work to ensure that drivers can see far enough to stop to avoid an accident or accelerate to join traffi
                                            </p>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                        <img src={RoadDesignImage}  className="img-fluid float-right" alt="RoadDesignImage"/>
                                </div>
                        </div>
                    </div>          
                </div>
            </div>       
    </div>)
}

export default RoadDesign;