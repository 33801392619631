import React, { Fragment } from 'react';

// import LoadingOverlay from 'react-loading-overlay'

// import HashLoader from 'react-spinners/HashLoader';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = ({ active })=>{

    return(<>
    {
        active == true && <CircularProgress />
    }
         {/* <LoadingOverlay  active={active}  styles={{ spinner: (base) => ({
            ...base,
            width: '200px',
            '& svg circle': {
                stroke: 'rgb(23,162,184)'
            }
        })
    }}
spinner={<HashLoader />} ></LoadingOverlay>   */}
    </>)

}
export default Loader
