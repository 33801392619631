import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { createTheme   } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import useFrontPage from '../common/useFrontPage';
import Loader from '../loader/Loader';

const useStyles = createTheme  ({
  table: {
    minWidth: 600,
    border: "2px solid blue",
    boxShadow: "5px 10px #999999",
  },
});

const FrontPageView = () => {
  const classes = useStyles();

  const history = useNavigate();

  const [frontView, frontViewError ] = useFrontPage();

 //console.log(frontView)
  
  const footerMove = () =>{

    history.push("/FrontPage");       
}
 
 
  return (<React.Fragment>
     <Loader active={ frontView.length ? false : true } />
      <Grid
      item
      container
      xs={12}
      sm={12}
      lg={12}
      md={12}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid lg={2} md={12}></Grid>

      <Grid
        item
        container
        xs={12}
        lg={8}
        md={8}
        spacing={2}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="caption table">
            <caption>SRC FOOTER VIEW </caption>
            <TableHead>
              <TableRow>
                <TableCell align="right">SNo</TableCell>
                <TableCell align="right">Logo Image</TableCell>
                <TableCell align="right">Kural Image</TableCell>
                <TableCell align="right">Profile Image</TableCell>
                {/* <TableCell align="right">Title</TableCell>
                <TableCell align="right">Image Download</TableCell>
                <TableCell align="right">Scrolling Image</TableCell>
                <TableCell align="right">Scrolling Content</TableCell> */}
                <TableCell align="right">Update</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {frontView.map((footer) => (
                <TableRow key={footer.RfId}>
                  <TableCell align="right">{ footer.RfId }</TableCell>     
                  <TableCell align="right"><img src={footer.LogoImage} width="150" height="100"  />  </TableCell>                
                  <TableCell align="right"><img src={footer.KuralImage} width="150" height="100"  /></TableCell>
                  <TableCell align="right"><img src={footer.ProfileImage} width="100" height="100"  /></TableCell>
                  {/* <TableCell align="right"><img src={footer.Title} width="100" height="100"  /></TableCell>
                  <TableCell align="right"><img src={footer.ImageDownload} width="100" height="100"  /></TableCell>
                  <TableCell align="right"><img src={footer.ScrollingImage} width="100" height="100"  /></TableCell>
                  <TableCell align="right"><img src={footer.ScrollingContent} width="100" height="100"  /></TableCell> */}
                  <TableCell align="right">
                    <Button variant="contained" color="primary" onClick={ footerMove } >
                        Update
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item lg={2} md={12}></Grid>
    </Grid>
  </React.Fragment>
  );
};

export default React.memo(FrontPageView);
