import React, { useRef, useState } from 'react';

import axios from 'axios';

const LoginGallery = ()=>{

    const [formData,setFormData] = useState({
        Remarks : ''
    })

    const [file, setFile] = useState(''); 

    const { Remarks } = formData;

    const el             = useRef();

  
    const handleChange  = (e)=>{

        const file = e.target.files[0]; // accesing file

        console.log(file);

        setFile(file);      
    }
    
    const onChange = e =>setFormData({ ...formData,[e.target.name] : e.target.value });
   

    const onSubmit =async(e)=>{

        e.preventDefault();       

        const body   = new FormData();

        body.append('gallery',file);
        
        body.append('Remarks',Remarks);

        body.append('action','Insert')

        // console.log(body);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }     

        axios.post("https://www.sreeragu.com/survey/api/webGallery/gallery",body,config).then((response)=>{

                console.log(response);

        }).catch((errMsg)=>{

                console.log(errMsg);

        })
    }  

    return(<div>      
       <div className="container">
        <h1>Gallery Images</h1><hr />
        <div className="row">
            <div className="col-md-3 col-lg-3"></div>
            <div className="col-md-6 col-lg-6">
            <form onSubmit={ (e)=>onSubmit(e) }>
                    <div className="form-group">
                        <label for="email">Gallery Images </label>
                        <input type="file" className="form-control"   ref={el} onChange={ handleChange } />
                    </div>
                    <div className="form-group">
                        <label for="pwd">Remarks </label>
                        <textarea className="form-control" col="5" name="Remarks" id="Remarks" value={Remarks} onChange={ onChange } >  </textarea>
                    </div>                   
                    <button type="submit" className="btn btn-info">Submit</button>
                </form>
            </div>
        </div>
       </div>
       <br /><br /> <br />
    </div>)
}

export default LoginGallery