import React from 'react';

import ShearTestImage from '../../asset/image/category/ShearTest.jpg' ;

import InnerImage from '../InnerImage';

const ShearTest =()=>{

    return(<div>
        <InnerImage name="Shear Test (Vane and Direct)" />
                    <div className="container">                    
                            <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                        <h4 className="body-heading-title">  Vane Shear Test </h4><br />
                                        <p className="body-heading-paragraph">The laboratory vane shear test for the measurement of shear strength of cohesive soils, is useful for soils of low shear strength of less than about 0.5 kgf/cm2). This test gives the undrained strength of the soil, in undisturbed as well as remoulded conditions both. Vane shear test is a cheaper and quicker method of measuring the shear strength of clay, as compared to very elaborate tri-axial shear test or direct shear test. 	The vane shall consist of four blades each fixed at 90° to the adjacent blades. The width of vane, from edge of one vane to edge of another vane, is 12±0.15 mm. The height of vane is 24±0.25 mm. The thickness of vane is 0.5±0.05 mm. The vane should not deform under the maximum torque for which it is designed. The penetrating edge of the vane blades shall be sharpened having an included angle of 90°. The vane blades shall be welded together suitably to a central rod, the maximum diameter of which should preferably not exceed 2.5 mm in the portion of the rod which goes into the specimen during the test. The height of rod, above the vanes, is 60mm. The vane should be properly treated to prevent rusting and corrosion.
                                        </p>                                        
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                            <img src={ShearTestImage}  className="img-fluid float-right" alt="ShearTestImage"/>
                                    </div>
                                    <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">                                                
                                                        <ul className="background-non-style">
                                                                    <li> 1 &nbsp;&nbsp;&nbsp;Prepare two or three specimens of the soil sample of dimensions of at least 30.0 mm diameter and 75 mm long specimen.</li>
                                                                    <li> 2 &nbsp;&nbsp;&nbsp;Mount the specimen container with the specimen on the base of the vane shear apparatus and fix it securely to the base.
                                                                    If the specimen container is closed at one end, it should be provided with a hole of about 1 mm diameter at the bottom.  </li>                          
                                                                    <li> 3 &nbsp;&nbsp;&nbsp;Gently lower the shear vanes into the specimen to their full length without disturbing the soil specimen. 
                                                                    The top of the vanes should be at least 10 mm below the top of the specimen. Note the readings of the angle of twist.  </li>                          
                                                                    <li> 4 &nbsp;&nbsp;&nbsp;Rotate the vanes at a uniform rate say 0.10/s by suitably operating the torque applicator handle until the specimen fails.  </li>                          
                                                                    <li> 5 &nbsp;&nbsp;&nbsp;Note the final reading of the torque indicator. Torque readings and the corresponding strain readings may also be noted at desired intervals of time as the test proceeds.  </li>                          
                                                                    <li> 6 &nbsp;&nbsp;&nbsp;Just after the determination of the maximum torque rotate the vane rapidly through a minimum of 10 revolutions. The remoulded strength should then be determined within 1 minute after completion of the revolution. </li>                          
                                                        </ul>
                                                        <h4 className="body-heading-title"> Direct Shear Test </h4><br />
                                                        <p className="body-heading-paragraph">A direct shear test is a laboratory or field test used by geotechnical engineers to measure the shear strength properties of soil or rock material, or of discontinuities in soil or rock masses.
                                                                The U.S. and U.K. standards defining how the test should be performed are ASTM D 3080, AASHTO T236 and BS 1377-7:1990, respectively. For rock the test is generally restricted to rock with (very) low shear strength. The test is, however, standard practice to establish the shear strength properties of discontinuities in rock.
                                                                The test is performed on three or four specimens from a relatively undisturbed soil sample. A specimen is placed in a shear box which has two stacked rings to hold the sample; the contact between the two rings is at approximately the mid-height of the sample. A confining stress is applied vertically to the specimen, and the upper ring is pulled laterally until the sample fails, or through a specified strain. The load applied and the strain induced is recorded at frequent intervals to determine a stress–strain curve for each confining stress. Several specimens are tested at varying confining stresses to determine the shear strength parameters, the soil cohesion (c) and the angle of internal friction, commonly known as friction angle. The results of the tests on each specimen are plotted on a graph with the peak (or residual) stress on the y-axis and the confining stress on the x-axis. The y-intercept of the curve which fits the test results is the cohesion, and the slope of the line or curve is the friction angle.
                                                                Direct shear tests can be performed under several conditions. The sample is normally saturated before the test is run, but can be run at the in-situ moisture content. The rate of strain can be varied to create a test of undrained or drained conditions, depending whether the strain is applied slowly enough for water in the sample to prevent pore-water pressure buildup. Direct shear test machine is required to perform the test. The test using the direct shear machine determinates the consolidated drained shear strength of a soil material in direct shear.
                                                                The advantages of the direct shear test over other shear tests are the simplicity of setup and equipment used, and the ability to test under differing saturation, drainage, and consolidation conditions. These advantages have to be weighed against the difficulty of measuring pore-water pressure when testing in undrained conditions, and possible spuriously high results from forcing the failure plane to occur in a specific location.

                                                        </p>
                                                </div>
                                    </div>
                            </div>
                    </div>
    </div>)


}

export default ShearTest;