import { createStore,applyMiddleware } from 'redux';

// import { composeWithDevTools } from 'redux-devtools-extension';

import {withExtraArgument } from 'redux-thunk';

import rootReducer from './redux/index';

const middleware = [withExtraArgument];

const initialState = {};

const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware)
)

export default store;