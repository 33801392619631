import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { createTheme   } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';

const useStyles = createTheme  ((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const  usePagination =()=> {
  const classes = useStyles();

  return (
    <div className={classes.root}>   
      <Pagination count={10} variant="outlined" shape="rounded" />
    </div>
  );
}

export default usePagination;
