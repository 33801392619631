import React from 'react';
import surveyNineImage from '../../asset/image/survey-9.jpg' ;
import surveyTenImage from '../../asset/image/survey-10.jpg' ;

import InnerImage from '../InnerImage';

const TunnelSurveying =()=>{

    return(<div>
        <InnerImage name="Tunnel Surveying" />       
                <div class="container">                    
                            <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <p class="body-heading-paragraph">This article presents the results of measurements made to determine the elements of real curve of the Balnaca tunnel, 
                                            values of misalignment of the tunnel elements and values of assigning the structure gauge. The data obtained from the measurements are carried out by a
                                            total station and a laser scanning system. The data are used by specialists when it comes to periodical verification or rehabilitation of tunnels. 
                                            Applied technology can be used by civil engineers in rehabilitation of railway tunnels.The specifics of the construction and modernization of the communication ways requires 
                                            specialized surveying assistance in all phases of implementing those projects (roads, railways, water streams, artwork, etc.), both in the study phase and in the design and execution phases.
                                            This applies also during the operational phase and modernization of infrastructure of communication ways. Each stage of the development of a specific communication pathway 
                                            involves surveying work, which - in the context of the design of modern communication ways - requires that the accuracy of the surveying measurements to be particularly high. 
                                            The reason gathering detailed, accurate information is important at this stage as now is when you need to define the particulars of the project. For instance, you will need to
                                            know where you’ll need to secure easements and exactly how wide those easements need to be. Then, you’ll have to work with the landowner and an attorney familiar with pipeline 
                                            law in the local jurisdiction to execute a legally binding agreement. In addition, you’ll also need to be aware of things like stream crossings, agricultural developments, 
                                            animal habitats, and historical areas. If your proposed pipeline right-of-way interferes with any of these, you may need to secure a special license to continue with your project or
                                                define how you intend to complete the project with the least amount of disruption possible.                                            
                                            </p>                                    
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <img src={surveyNineImage}  class="img-fluid float-right" alt="surveyNineImage"/> 
                                            <img src={surveyTenImage}  class="img-fluid float-right" alt="surveyTenImage"/>                                             
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <p class="body-heading-paragraph">
                                            In this context, an important role lies on geodetical and topographical works required for modernization artworks for communication ways. From the many types of artworks specific to
                                            communication ways are distinguished - in particular - tunnels, special constructions through the complexity of design solutions, the difficulties during construction but also by the
                                            need for special maintenance programs, time tracking and modernization during operations. Based on these considerations, I realized an application regarding the contribution of geodetic 
                                            and topographic works in the operational phase of a tunnel, phase which involves - in equal measure – maintenance, monitoring behavior in time and modernization such an artwork. 
                                            Surveying measurements have been made at Balnaca tunnel, located on the railway line CF Cluj - Oradea, between railway stations Bratca - Şuncuiuş, Km 598 + 930.00 m – 599 + 203.50 m,
                                            in the county of Bihor. The tunnel is located about 50 km from the city of Oradea, positioned at opening of Quick Cris valley towards the plain, in an area of contact between the
                                                Apuseni Mountains and Banato-Crisan Plain, area of transition from hills relief (Western Hills, the hills of Oradea and Gepis hills) to the plains. The tunnel has a length of 273.50 m 
                                                and was built by MAV (Magyar Államvasutak) hungarian railway company, between 1870-1871. In the longitudinal profile, the line in the tunnel is in: ramp 8.0‰, in plan, the tunnel is 
                                                a curved line, continuous radius of 280 m, connected to the exit of the tunnel (towards Oradea) with reverse curve. In longitudinal section: 2 portals (entrance portal L = 3.60m and 
                                                exit portal L = 3.60m) and 53 rings with a length between 4.00 and 8.00 m The bridging network consists of six points: determined by GNSS technologies, for the purpose of carrying out 
                                                surveying works needed for the project of modernization the railway between Braşov - Oradea. The planimetric positions of the bridging network points are defined by coordinates (X, Y) 
                                                in the projection system "Stereographic 1970” (on customer request). Altimetric position of points is defined in the reference system "Black Sea 1975" and was determined through 
                                                geometric levelling. The minor control framework was designed as a traverse supported at both ends with control points, taking into account the ground conditions (tunnel in curve),
                                                restricted visibility, narrow work area, rail traffic, etc. Measurements for the determination of the coordinates of detail points (raceway geometric elements, the upper rail level)
                                                have been carried out by a total station from Sokkia and a laser scanning system ScanStation 2 manufactured by Leica. 2 Determining the actual curve elements of the tunnel Tunnels 
                                                in curve are exposed to changes in outlets, in particular with transverse displacements towards the inside of the curve. For the specialized designer determination of the actual 
                                                curve elements and comparing them to the designed elements of the curve plays an important role in choosing the method of working for tunnel rehabilitation especially in modernization 
                                                costs. Also, the need for designing a new curve, in the case of an improper classification of the structure gauge of the real curve or redrawing existing curve are outstanding 
                                                issues that must be taken into account in the procedure for modernization of tunnels. The elements of designed curve at Balnaca tunnel, are known from design of tunnel and tunnel 
                                                Fig.2 - Exit portal tunnel Recent Advances in Geodesy and Geomatics Engineering ISBN: 978-960-474-335-3 173 sheet, elements of real curve is calculated based on measurements made in
                                                the wheel tracks. In order for the specialized designer to be able to establish a viable solution for the need of re-setting out the real curve on the projected position from 
                                                measurements, the following elements are calculated: - Δt - misalignment between the axis of the shaft and runways tunnel; - ΔCFp existing misalignment between the existing tread 
                                                axis and design tread exis.; - minimum horizontal distances separating gauge tunnel soffit, Gd (right gauge), Gs (left gauge) Data obtained for Δt misalignment and ΔCFp misalignment 
                                                were measured by superimposing real curve over the projected and over the tunnel axis at each point of the tunnel rings (Fig.4). The resulted dimensions are centralized in the tables 
                                                (Table 2), the last column is calculated by the designer specialist if the modernization solution requires a different axis of the track from the designed axis, where ΔCFa is 
                                                misalignment between existing tread axis and the new proposed tread axis. Determine the minimum horizontal distance separating gauge tunnel soffit, Gd (right gauge), Gs (left gauge), 
                                                structure gauge was done according to STAS 4392 (Fig.5), depending on the radius of the curve R, over-expansion to the outside of the curve Se, over-expansion towards the inside of the 
                                                curve Si, over-exaltation outer rail h and increase the distance between track centers Sc. The values of these elements are extracted from tables: R = 277m, Si = Se = 131.5mm, 
                                                Sc = 17.3mm, H = 150mm. Calculated structure gauge (Fig.5) was superimposed on each profile performed at joints between rings, measuring values, Gd (right gauge) and Gs (left gauge). 
                                                Extracted gauges are centralized in the same tables with misalignments, the values entered in the table are minimum values measured in a section gauge, both the left and the right gauge. 
                                                The minimum value accepted by standards for difference in gauge - soffit is 220 mm. The minimum value is exceeded for some gaps on the inside of the curve due to the misalignment of the 
                                                running axis to the designed axis. In this case, usually it retraces circular curve on the design position.
                                        </p>
                                    </div>
                            </div>
                </div>
    </div>)
}

export default TunnelSurveying;