import React from 'react';

import AugerTractorImage from '../../asset/image/category/AugerTractor.jpg';

import InnerImage from '../InnerImage';

const AugerTractor = ()=>{

    return(<div>

        <InnerImage name="Auger Tractor" />      
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                                <h4 className="body-heading-title"> Auger Tractor </h4><br />
                                                <p className="body-heading-paragraph" >Commonly referred to as Post Hole Diggers and Earth Augers, Tractor PTO Augers are implements used in conjunction with a tractor's Power Take Off drive, and a tractor's 3-point hitch.
                                                    The basic concept of a tractor PTO auger is to harness the tractor's available energy by attaching a PTO shaft to a tractor's PTO drive in order to drill a hole of predetermined size (size of the auger shaft and diameter) and depth into the ground. This in turn will provide power to the Tractor PTO Auger's gearbox. Most modern [1] Tractor PTO Auger gearboxes come standard with a shear bolt to protect the gear drive if the auger encounters an obstruction such as rock during drilling a hole.
                                                    Tractor PTO Augers connect via 3-point hitch to subcompact tractors and mid-size tractors.
                                                </p>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                            <img src={AugerTractorImage}  className="img-fluid float-right"  alt="AugerTractorImage"/>
                                    </div>
                            </div>
                        </div>         
                </div>
            </div>
    </div>)


}

export default AugerTractor;