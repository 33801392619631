import React,{useState} from 'react';
import Form from './form/Form';
import useFrontContent from '../pages/common/useFrontContent';
import Loader from '../pages/loader/Loader';
import Carosel from '../pages/carosel/Carousel';

const Home = ()=>{    

    const [frontContent,frontContentError] = useFrontContent();

    const leftContents =  frontContent.length && frontContent.map((content,index)=>{
        return( <div className="jumbotron index-left-bar jumbotronLeftPanel" key={ index } dangerouslySetInnerHTML = {{ __html : content.LeftContent }} >                                                                   
              
    </div>)
    })

    const bottomContents = frontContent.length && frontContent.map((bottom,index)=>{
      return(<div  key={ index } >

            <h6>{ bottom.BottomContent.split("<br />").map((i,key) => {
                                                                    return <p key={key}>{i}</p>;
                    }) } </h6> 

      </div>)   
    })
    const bottomBelow= frontContent.length && frontContent.map((bottom,index)=>{
      return(<div  key={ index } >

            <h6>{ bottom.frontBottom.split("<br />").map((i,key) => {
                                                                    return <p key={key}>{i}</p>;
                    }) } </h6> 

      </div>)   
    })

    

    return(<div>       
                <Loader active={ frontContent.length ? false : true } />
                <div className="container-fluid">
                          
                    <div className="row">
                        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            {/* <div className="jumbotron index-left-bar">
                                <h6 className="home-page-margin"> New Updates Pdf <a href="https://www.sreeragu.com/survey/File/images/front-page/4.jpg"> Download </a> </h6>

                                <h6 className="home-page-margin">Schedule of Rates</h6>

                                <h6 className="home-page-margin">Survey Books</h6>

                                <h6 className="home-page-margin">Method of DPR</h6>

                                <h6 className="home-page-margin">Calculation Of Books</h6>

                                <h6 className="home-page-margin">New Advance Equipment</h6>

                                <h6 className="home-page-margin">Patta Chitta and FMB</h6>                                               

                            </div> */}
                            {
                                leftContents == 0 ? '' :  leftContents
                            }
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">

                            <div className="jumbotron index-Center-bars row ">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <Carosel />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 justifyContent">
                                {
                                    bottomContents == 0 ? '' : bottomContents
                                }
                                </div>

                               <div className="col-sm-12 col-md-12 col-lg-12 justifyContents">
                                {
                                    bottomBelow
                                }
                               </div>
                            </div>
                          
                            {/* <div className="jumbotron index-left-bar">

                                <h6>Locate the text where you want to change the font. We'll use this as an example: ...
                                Surround the text with the SPAN element: ...
                                Add the attribute style="" to the span tag: ...
                                Within the style attribute, change the font using the font-family style. ...
                                Save the changes to see the effects.</h6>

                                <h6>Locate the text where you want to change the font. We'll use this as an example: ...
                                Surround the text with the SPAN element: ...
                                Add the attribute style="" to the span tag: ...
                                Within the style attribute, change the font using the font-family style. ...
                                Save the changes to see the effects.</h6>

                                <h6>Locate the text where you want to change the font. We'll use this as an example: ...
                                Surround the text with the SPAN element: ...
                                Add the attribute style="" to the span tag: ...
                                Within the style attribute, change the font using the font-family style. ...
                                Save the changes to see the effects.</h6>

                                <h6>Locate the text where you want to change the font. We'll use this as an example: ...
                                Surround the text with the SPAN element: ...
                                Add the attribute style="" to the span tag: ...
                                Within the style attribute, change the font using the font-family style. ...
                                Save the changes to see the effects.</h6>

                                <h6>Locate the text where you want to change the font. We'll use this as an example: ...
                                Surround the text with the SPAN element: ...
                                Add the attribute style="" to the span tag: ...
                                Within the style attribute, change the font using the font-family style. ...
                                Save the changes to see the effects.</h6>

                            </div>      */}
                        </div>
                       <Form />


                    </div>
                </div>
        </div>)
}

export default Home