import React from 'react';

import MITankDesignImage from '../../asset/image/category/MITankDesign.jpg' ;

import InnerImage from '../InnerImage';

const MITankDesign = ()=>{

    return(<div>

        <InnerImage name="MI Tank Design" />       
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                                <h4 className="body-heading-title">MI Tank Design </h4><br />
                                                <p className="body-heading-paragraph" >Preparation of Feasible Report of the area, where MI Tank to be constructed by annual average rainfall, 
                                                streams that pass through, ayacut area to get benefit. Design of suitable width of masonry weir, sluice required,
                                                soil classNameification for bund formation. Determining and Fixing of levels with respect to MSL which is a supreme factor. 
                                                Determining total Water Spread Area for arriving capacity of the tank.</p>
                                
                                                <p className="body-heading-paragraph" >Engineering Surveying and Preparation of DPR by taking Spot Levels with FMB clubbing comparing to actual 
                                                site boundary for MI Tank by conducting Compass Surveying using Total Station Instrument, providing spot levels at 10m x 10m grid interval 
                                                and providing contour interval at 0.30m interval, precisely carrying out Deep Bed Level of the tank, Sill Level, Full Tank Level (FTL),
                                                Maximum Water Level (MWL) and Top Bund Level (TBL) of the Tank and finding out the exact area of the water spread zone.
                                                </p>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                 <img src={MITankDesignImage}  className="img-fluid float-right" alt="MITankDesignImage"/>
                                </div>
                        </div>
                </div>          
            </div>
        </div>
    </div>)

}

export default MITankDesign