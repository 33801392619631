import React from 'react';

import SoilTestingImage from '../../asset/image/category/SoilTesting.jpg';

import InnerImage from '../InnerImage';

const SoilTesting =()=>{

    return(<div>

        <InnerImage name="Soil Testing" /> 

                <div className="container">    
                                <div className="row">
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <img src={SoilTestingImage}  className="img-fluid float-right" alt="SoilTestingImage"/>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">

                                                <h4 className="body-heading-title"> Soil Testing </h4><br /><br /><br />
                                                        <ul className="background-non-style">
                                                            <li> ✓ &nbsp;&nbsp;&nbsp;Wash Boring Machine.</li>
                                                            <li> ✓ &nbsp;&nbsp;&nbsp;SPT sampler (Split Spoon). </li>
                                                            <li> ✓ &nbsp;&nbsp;&nbsp;Direct Shear Testing Machine. </li>
                                                            <li> ✓ &nbsp;&nbsp;&nbsp;Vane Shear Apparatus. </li>
                                                            <li> ✓ &nbsp;&nbsp;&nbsp;Compaction Moulds. </li>
                                                            <li> ✓ &nbsp;&nbsp;&nbsp;Sieve Analysis. </li>
                                                            <li> ✓ &nbsp;&nbsp;&nbsp;Compression Testing Machine. </li>
                                                            <li> ✓ &nbsp;&nbsp;&nbsp;Permeameter. </li>
                                                            <li> ✓ &nbsp;&nbsp;&nbsp;Pycnometer. </li>
                                                            <li> ✓ &nbsp;&nbsp;&nbsp;Hydrometer. </li>
                                                            <li> ✓ &nbsp;&nbsp;&nbsp;CBR Apparatus. </li>
                                                        </ul>
                                            </div>
                                </div>   
                </div>
            </div>)
}

export default SoilTesting