import React from 'react';

import surveySixImage from '../../asset/image/survey-6.jpg' ;
import surveySevenImage from '../../asset/image/survey-7.jpg' ;
import surveyEightImage from '../../asset/image/survey-8.jpg' ;

import InnerImage from '../InnerImage';

const PipeLineSurveying =()=>{

    return(<div>
        <InnerImage name="Pipe Line Surveying" />      
                    <div className="container">                
                        <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p className="body-heading-paragraph">During Water Supply Scheme Projects, surveying the pipeline right-of-way can be among the most difficult aspects of the entire project.
                                        While most development projects are centralized in one area, pipelines must cross many miles of terrain, which will inevitably lead to issues, such as your pipeline crossing
                                        through privately owned land. In the very likely event that this occurs, you’ll have to be able to work with the property owner to secure an easement on the land, giving you 
                                        the right to access the land during the pipeline construction process and later on to perform ongoing monitoring and maintenance of the completed pipeline.Simply put, 
                                        the pipeline right-of-way is the strip of land where the pipeline will be built. It may be anywhere between 25 and 150 feet wide, depending on the diameter of the pipe itself. 
                                        As suggested earlier, pipeline right-of-ways must be kept available to you even after the initial development has taken place, regardless of who owns the land. If you secure an 
                                        easement with a private landowner, they are still free to use the land, but may have to give up certain rights. For instance, the land owner would have to give up the right to develop
                                        the land themselves, as an unobstructed view of the land must be available in order to conduct aerial monitoring.Once the initial land survey is complete, there’s still the final
                                            construction survey left to think about. If you work with a land surveying partner to help you complete your pipeline development project, that partner will be able to help during 
                                            the construction staking in order to facilitate a streamlined development. During the actual construction process itself, the land surveyor can work with you to provide detailed and 
                                            accurate as-built surveys. These surveys are needed for your own project recordkeeping as well as to prove to the private landowners that you are working in accordance of the easement. 
                                            As a result, land surveying technology and services play a very important role in ensuring an effective, problem-free pipeline project. The reason gathering detailed, accurate information 
                                            is important at this stage as now is when you need to define the particulars of the project. For instance, you will need to know where you’ll need to secure easements and exactly how wide 
                                            those easements need to be. Then, you’ll have to work with the landowner and an attorney familiar with pipeline law in the local jurisdiction to execute a legally binding agreement.
                                            In addition, you’ll also need to be aware of things like stream crossings, agricultural developments, animal habitats, and historical areas. If your proposed pipeline right-of-way 
                                            interferes with any of these, you may need to secure a special license to continue with your project or define how you intend to complete the project with the least amount of disruption 
                                            possible.                                        
                                        </p>                                
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <img src={surveySixImage}  className="img-fluid float-right" alt="surveySixImage"/> 
                                        <img src={surveySevenImage}  className="img-fluid float-right" alt="surveySevenImage" /> 
                                        <img src={surveyEightImage}  className="img-fluid float-right" alt="surveyEightImage"/>  
                                </div>
                        </div>
                    </div>
    </div>)

}

export default PipeLineSurveying;