import React from 'react';
const initialState = {
    loading : true,
    data : [],
    error : ''
}

const WebGalleryReducer = (state=initialState,action)=>{

    switch(action.type){

        case 'GALLERYINSERT' : 
                return{
                    ...state,
                    loading : false,
                    data : action.payload,
                    error : ''
                }
        case 'GALLERYVIEW' : 
                return{
                    ...state,
                    loading : false,
                    data : action.payload,
                    error : ''
                }
        case 'GALLERYERROR' :
                return{
                    ...state,
                    loading : false,
                    data : '',
                    error : action.error
                }
        default :
              return state;   

    }

}

export default WebGalleryReducer