import React from 'react';

import HandGPSImagess from '../../asset/image/category/HandGPS.jpg';

import InnerImage from '../InnerImage';

const HandGPS = ()=>{

    return(<div>

        <InnerImage name="Hand GPS" />       

        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                            <h4 className="body-heading-title"> Hand GPS </h4><br />
                                            <p className="body-heading-paragraph" >A handheld GPS is a device that uses the Global Positioning System, combining modern geographic technology with a portable, user-friendly device for everyday use. Introduced in the late 1990s, the handheld GPS has many functions, including navigation assistance and land-survey data. Features on some models may also provide information on geographic locations like national and historic landmarks. The device is often used by outdoors enthusiasts to pinpoint the coordinates of an certain location for future reference.
                                                Although the handheld system is capable of performing many practical tasks, some individuals purchase the portable version for fun. Aside from its conventional purpose, the handheld GPS has become widely used for an outdoor treasure-seeking game known as geocaching. Based on an old English game known as letterboxing, the activity is a treasure hunt that involves either teams or individuals who leave a “cache,” usually a small plastic container full of toys and surprises, hidden at a certain geographic coordinate. The team or person then releases these coordinates for others with a GPS handheld to find. The hobby is an international phenomenon that continues to grow in popularity as the handheld technology becomes more affordable.

                                                One potential drawback for consumers in the market for a handheld GPS is its geographic reliability. Unlike its larger predecessor, the handheld GPS relies on a minimum of three available satellites, therefore making it less reliable, with a typical accuracy of within about 100 feet (30 m). A military mandate restricts the devices from maintaining an accuracy capable of within about 16 feet (5 m).
                                            </p>                                        
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                        <img src={HandGPSImagess}   className="img-fluid float-right" alt="HandGPSImage"/>
                                    </div>
                        </div>
                    </div>          
            </div>
        </div>
    </div>)
}

export default HandGPS