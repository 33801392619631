import React from 'react';

import TunnelDesignImage from '../../asset/image/category/TunnelDesign.jpg';

import InnerImage from '../InnerImage';

const TunnelDesign = ()=>{

    return(<div>
        <InnerImage name="Tunnel Design" />     
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                    <h4 className="body-heading-title"> Tunnel Design </h4><br />
                                        <p className="body-heading-paragraph" >The tunnel engineering is one of the most interesting disciplines in engineering. The work is complex and difficult throughout its course, even though it is interesting. The tunnels are defined as the underground passages that are used for the transportation purposes. These permit the transmission of passengers and freights, or it may be for the transportation of utilities like water, sewage or gas etc. The operations and the constructions are carried out underground without disturbing the ground surface. This operation is called as the tunnelling. 
                                            It is found that the first tunnel was made by the Egyptians and the Babylonians, 4000 years ago. This tunnel served the purpose of connecting two buildings in Babylon. The connection was from the royal palace to the temple. The length of this tunnel was found to be 910m, which was brick lined.
                                            The largest tunnel in ancient times was constructed between Naples and Pozzuoli, in 36.B.C. This tunnel was 4800-foot-long, 25-foot-wide and 30 foot in height. It was a road tunnel.
                                            During the second half of the twentieth century, the development and progress of explosives and sophisticated equipment were flourished, that made the tunnelling process more feasible. 
                                            The tunnelling, work better for different approaches under different circumstances. It is hence dependent on the experience and the knowledge of the engineer. 
                                            Mainly the tunnelling works are carried out by the civil engineers. This discipline will let us know, that the knowledge on structural and concrete technology is not sufficient.
                                            An equal and thorough skill and knowledge on geology, mechanical engineering, geomechanics and the new construction technologies are also essential. 

                                        </p>
                                        <p className="body-heading-paragraph" >The two main factors that help in the efficient route of the tunnel are the alignment restraints and the environmental considerations.
                                        The underground, as we know is heterogeneous in nature. A proper inspection on the nature of soil, rock, the water table level,
                                        and all the alignment restraints had to be made before fixing the route. The site chosen for tunnelling is such a way that the inconvenience and difficulty that is
                                        caused to the environment in that area including living is minimum. The tunnelling method chosen depends on the ground conditions, the water table level, 
                                        the tunnel drive length and the diameter, the tunnel depth, final utility requirements, the shape of the tunnel and the risk of construction.

                                        </p>
                                        <ul className="background-non-style">
                                            <li> ✓ &nbsp;&nbsp;&nbsp;The best and economical alignment was chosen must be straight in nature.</li>
                                            <li> ✓ &nbsp;&nbsp;&nbsp;Tunnel should have a grade, which is less than the outside. It is observed that in the railway tunnels,
                                            constant slipping of the wheels takes place due to the wetness of the rails. This reduces the hauling capacity of the locomotives. </li>
                                            <li> ✓ &nbsp;&nbsp;&nbsp;0.2% gradient must be provided to ensure proper drainage. </li>
                                            <li> ✓ &nbsp;&nbsp;&nbsp;When it comes to long tunnels, two grades at the either ends must be provided (That rise from each end then towards the centre as shown in figure-3). </li>
                                            <li> ✓ &nbsp;&nbsp;&nbsp;If the grade is provided on one side, instead of either side, the effectiveness of ventilation can be increased. </li>
                                        </ul>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                        <img src={TunnelDesignImage}   className="img-fluid float-right" alt="TunnelDesignImage"/>
                                </div>
                            </div>
                        </div>        
                </div>
        </div>
    </div>)


}

export default TunnelDesign;