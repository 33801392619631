import React from 'react';

import surveyTwoImage from '../../asset/image/survey-2.jpg' ;
import surveyThreeImage from '../../asset/image/survey-3.jpg' ;

import InnerImage from '../InnerImage';

const HighwaySurveying =()=>{

    return(<div>
        <InnerImage name="Highway Surveying" />        
                    <div className="container">                        
                                <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <p className="body-heading-paragraph">Contours are those lines you can see on OS maps. A contour line is an imaginary line that joins points of equal height above sea level. 
                                            They can be used to learn about the shape of the land (the relief). A map with only a few contour lines will be flat (and often low lying). If a map has lots of contours 
                                            it is a mountainous or hilly area. The actual pattern of the lines will tell you more detail about the area too. Steep slopes Area that is flatter with only a gentle slope Contour, 
                                            an imaginary line on the ground surface joining the points of equal elevation is known as contour. In other words, contour is a line in which the ground surface is intersected by 
                                            a level surface obtained by joining points of equal elevation. This line on the map represents a contour and is called contour line. Contour Map: A map showing contour
                                            lines is known as Contour map. A contour map gives an idea of the altitudes of the surface features as well as their relative positions in plan serves the purpose of both,
                                            a plan and a section. The process of tracing contour lines on the surface of the earth is called Contouring. Contour survey is carried out at the starting of any engineering project 
                                            such as a road, a railway, a canal, a dam, a building etc for preparing contour maps in order to select the most economical or suitable site. To locate the alignment of a canal so 
                                            that it should follow a ridge line and mark the alignment of roads.
                                            </p>                                        
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <img src={surveyTwoImage}  className="img-fluid float-right" alt="surveyTwoImage"/>
                                                <img src={surveyThreeImage}  className="img-fluid float-right" alt="surveyThreeImage"/>
                                        </div>
                                </div>
                    </div>
    </div>)
}

export default HighwaySurveying