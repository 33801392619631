import React from 'react';

import FieldDensityImage from '../../asset/image/category/FieldDensity.jpg' ;

import InnerImage from '../InnerImage';

const FieldDensity = ()=>{

    return(<div>
        <InnerImage name="Field Density Test" />  
                        <div className="container">                            
                                    <div className="row">
                                            <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                                    <p className="body-heading-paragraph">The field density test of soil is conducted in the field to know whether the specified compaction is achieved or not. Normally Sand Replacement Method is adopted for this purpose.
                                                        Sand Replacement Method is also known as Sand Cone Method.
                                                        The apparatus used in this field density test consists of a sand pouring cylinder, with pouring cone at its base.
                                                        There is a shutter between the cylinder and the cone. The cylinder is first calibrated to determine the unit Weight of sand.
                                                        For good results, the sand used should be uniform, dry and clean passing a 1.0 mm sieve and retained on a 600-micron sieve.
                                                        </p>
                                                        <h4 className="body-heading-title">  Test Standard  </h4><br />
                                                    
                                                        <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                            <th scope="col">AASHTO</th>
                                                                            <th scope="col">T99-86</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>ASTM</td>
                                                                                <td>D 446-82</td>                                               
                                                                            </tr>
                                                                            <tr>                                    
                                                                                <td>BS1377</td>
                                                                                <td>Part 4</td>                                                
                                                                            </tr>                                           
                                                                        </tbody>
                                                                        </table>                                                    
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                    <img src={FieldDensityImage}  className="img-fluid float-right" name="FieldDensityImage"/>
                                            </div>

                                            <div className="row">
                                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                        <h4 className="body-heading-title"> Apparatus </h4><br /><br />
                                                                        <ul className="background-non-style">
                                                                            <li> 1 &nbsp;&nbsp;&nbsp;Sand cone apparatus, filled with uniformly graded sand passing through No. 20 sieve and retained on No. 30 sieve.</li>
                                                                            <li> 2 &nbsp;&nbsp;&nbsp;Density Plate, with a central circular hole of diameter equal to the diameter of pouring cone.. </li>                          
                                                                            <li> 3 &nbsp;&nbsp;&nbsp;Digging tools. </li>                          
                                                                            <li> 4 &nbsp;&nbsp;&nbsp;Balances sensitive to 1 gm & 0.1 gm. </li>                          
                                                                            <li> 5 &nbsp;&nbsp;&nbsp;Spoon. </li>                          
                                                                            <li> 6 &nbsp;&nbsp;&nbsp;Brush. </li>                          
                                                                            <li> 7 &nbsp;&nbsp;&nbsp; Moisture containers. </li>                          
                                                                            <li> 8 &nbsp;&nbsp;&nbsp;Oven. </li>  
                                                                        </ul>
                                                                        <h4 className="body-heading-title"> Apparatus </h4><br />

                                                                        <p className="body-heading-paragraph">(a) Calibration of Apparatus.
                                                                                    The cylinder is filled with sand weighed. A calibrating container is placed below the pouring cylinder, and the shutter is opened.
                                                                                    The sand fills the calibrating container and the cone.
                                                                                    The weight of the sand in the container and the cone is equal to the difference between the two observations.
                                                                                    The pouring cylinder is again filled to the initial weight.
                                                                                    The sand is allowed to run out of the cylinder, equal to the volume of the container and the shutter is closed.
                                                                                    The cylinder is then placed over a plain surface, and the shutter is opened, the sand runs out of the of the cylinder and fills the cone.
                                                                                    The shutter is closed when no further movement of sand takes place. The cylinder is removed and the sand formerly filling the cone is collected and weighed.
                                                                                    The unit weight of the sand is determined as under:
                                                                                    γ = (w1-w2)-w3 / V
                                                                                    Where,
                                                                                    w1 = initial weight of cylinder, with sand.
                                                                                    w2 = weight of sand in the cone only.
                                                                                    w3 = weight of cylinder after pouring sand into the cone and the container.
                                                                                    Vc= Volume of the container.
                                                                                    (b) Measurement of volume of the hole.
                                                                                    A tray with a central hole is placed on the prepared ground surface which had been cleaned and properly leveled.
                                                                                    A hole about 100 mm diameter and 150 mm deep is excavated in the ground, using the hole in the tray as a pattern.
                                                                                    The soil removed, is carefully collected and weighed (w).
                                                                                    The sand pouring cylinder is placed over the excavated hole. The shutter is opened, and the sand is filled in the cone and the hole.
                                                                                    When the sand stops running out, the shutter is closed. The cylinder is removed and weighed.
                                                                                    The volume of the hole is determined by the weight of sand filled in the hole and the unit weight of sand.
                                                                                                                γs = (w1-w2)-w3 / Vc.
                                                                                    Where,
                                                                                    w1 = weight of cylinder and sand before pouring into the hole.
                                                                                    w2 = weight of sand in the cone only.
                                                                                    w3= weight of cylinder after pouring sand into the hole.
                                                                                    γs= unit weight of sand, as found from calibration.
                                                                                    The bulk unit in-situ soil is determined from the weight of soil excavated and the volume of the hole.
                                                                                    The Wet density or bulk density of soil is computed as:
                                                                                    γ = W/V
                                                                                    After determining the water content (mc) of soil, the dry density of soil is computed as:
                                                                                    γd =  γ/1+m.c
                                                                                </p>
                                                            </div>
                                            </div>

                                        </div>
                        </div>
    </div>)

}

export default FieldDensity;