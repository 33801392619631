import { useState,useEffect } from 'react';
import { webConfig,config,formData } from '../../url/CommonUrl';

import axios from 'axios';

export const useScroll = ()=>{

    const [datas,setDatas] = useState([]);
    const [errors,setErrors] = useState([]);

    const galleryGet = ()=>{
        axios.post(`${ webConfig }/scrollGallery/scroll`,formData,config).then((response)=>{

            setDatas(response.data.data);

        }).catch((errMsg)=>{
            setErrors(errMsg);
        })
    }

    useEffect(()=>{
          galleryGet()  
    },[datas])
  
    return [datas]
}


export const WebScroll = ()=>{

    const [datas,setDatas] = useState([]);
    const [errors,setErrors] = useState([]);

    const galleryGet = ()=>{
        axios.post(`${ webConfig }/scrollGallery/scroll`,formData,config).then((response)=>{

            setDatas(response.data.data);

        }).catch((errMsg)=>{
            setErrors(errMsg);
        })
    }

    useEffect(()=>{
          galleryGet()  
    },[])
  
    return [datas]
}
