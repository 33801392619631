import React from 'react';
import Profile from '../asset/image/profile.jpg';
import InnerHtml from './InnerImage';

const AboutUs = ()=>{
    return(<div>
               <InnerHtml name="ABOUT US" />               
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className="row">
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">

                                                    <h4 className="body-heading-title"> COMPANY HISTORY </h4><br />
                                                    <p className="body-heading-paragraph" >The Company was established in the year 2012 by Mr.Ragupathi.T as a sole proprietor in the year 2012 in Erode District. 
                                                    The main reason to start the company is to show the common people about the recent techniques in Survey and give them potential
                                                    ideas to get an accurate measurement of their land</p>
                                                    <ul className="background-non-style">
                                                        <li> ✓ &nbsp;&nbsp;&nbsp;To be a respectful and adorable Consultancy company in providing quality work progress.</li>
                                                        <li> ✓ &nbsp;&nbsp;&nbsp;To provide safe and secure environment to the employees. </li>
                                                        <li> ✓ &nbsp;&nbsp;&nbsp;To withhold the clients in fulfilling their better needs and providing better service. </li>
                                                        <li> ✓ &nbsp;&nbsp;&nbsp;To be a responsible corporate company in country’s economic growth. </li>
                                                        <li> ✓ &nbsp;&nbsp;&nbsp;To develop and deliver services beyond the expectations of the customers. </li>
                                                    </ul>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    <img src={Profile}  className="img-fluid float-right" alt="Src" />
                                            </div>
                                    </div>
                                    <br />  <br /> <br />
                                    <div className="row">
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <img src={Profile}  className="img-fluid float-right"  alt="Src" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    <h4 className="body-heading-title"> WE ARE 6 YEARS IN INDUSTRY </h4><br />
                                                            <p className="body-heading-paragraph" >The Company was established in the year 2012 by Mr.Ragupathi.T as a sole proprietor in the year 2012 
                                                                in Erode District. The main reason to start the company is to show the common people about the recent techniques in Survey and give 
                                                                them potential ideas to get an accurate measurement of their land. We mainly focus on Government projects, Private Sectors and Mass
                                                                land Owners who need accurate measurements.</p>
                                                            <ul className="background-non-style">
                                                                <li> ✓ &nbsp;&nbsp;&nbsp;Efficient surveyors and skilled labours.</li>
                                                                <li> ✓ &nbsp;&nbsp;&nbsp;Accurate measurements and drawings. </li>
                                                                <li> ✓ &nbsp;&nbsp;&nbsp;Reliable and quick access to the spot. </li>
                                                                <li> ✓ &nbsp;&nbsp;&nbsp;Proper co-ordination and implementations. </li>
                                                                <li> ✓ &nbsp;&nbsp;&nbsp;Updating to the recent and latest technologies for valiant outputs. </li>
                                                            </ul>
                                                </div>
                                    </div> 
                        </div>
                    </div>
                </div>
            </div>)
}

export default AboutUs