import React from 'react';

import DifferentialGlobalPositionSystemImage from '../../asset/image/category/DifferentialGlobalPositionSystem.png';

import InnerImage from '../InnerImage';

const GlobalPositionSystem =()=>{

    return(<div>
        <InnerImage name="Differential Global Position System" />       

            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                                <h4 className="body-heading-title"> Differential Global Position System </h4><br />
                                                <p className="body-heading-paragraph" >A Differential Global Positioning System (DGPS) is an enhancement to the Global Positioning System (GPS) which provides improved location accuracy, in the range of operations of each system, from the 15-meter nominal GPS accuracy to about 1-3 cm in case of the best implementations.
                                                    Each DGPS uses a network of fixed ground-based reference stations to broadcast the difference between the positions indicated by the GPS satellite system and known fixed positions. These stations broadcast the difference between the measured satellite pseudo ranges and actual (internally computed) pseudo ranges, and receiver stations may correct their pseudo ranges by the same amount. The digital correction signal is typically broadcast locally over ground-based transmitters of shorter range.
                                                    The United States Coast Guard (USCG) and the Canadian Coast Guard (CCG) each run DGPSs in the United States and Canada on longwave radio frequencies between 285 kHz and 325 kHz near major waterways and harbours. The USCG's DGPS was named NDGPS (Nationwide DGPS) and was jointly administered by the Coast Guard and the U.S. Department of Defence’s Army Corps of Engineers (USACE). It consisted of broadcast sites located throughout the inland and coastal portions of the United States including Alaska, Hawaii and Puerto Rico.[2] Other countries have their own DGPS.
                                                </p>
                                                <p className="body-heading-paragraph" >A similar system which transmits corrections from orbiting satellites instead of ground-based transmitters is called a Wide-Area DGPS (WADGPS) or Satellite Based Augmentation System.
                                                A reference station calculates differential corrections for its own location and time. Users may be up to 200 nautical miles (370 km) from the station, however, and some of the compensated errors vary with space: specifically, satellite ephemeris errors and those introduced by ionospheric and tropospheric distortions. For this reason, the accuracy of DGPS decreases with distance from the reference station. The problem can be aggravated if the user and the station lack "inter visibility"—when they are unable to see the same satellites.
                                                    The United States Federal Radio navigation Plan and the IALA Recommendation on the Performance and Monitoring of DGNSS Services in the Band 283.5–325 kHz cite the United States Department of Transportation's 1993 estimated error growth of 0.67 m per 100 km from the broadcast site but measurements of accuracy across the Atlantic, in Portugal, suggest a degradation of just 0.22 m per 100 km.
                                                    Post-processing is used in Differential GPS to obtain precise positions of unknown points by relating them to known points such as survey markers.
                                                The GPS measurements are usually stored in computer memory in the GPS receivers, and are subsequently transferred to a computer running the GPS post-processing software. The software computes baselines using simultaneous measurement data from two or more GPS receivers.
                                                The baselines represent a three-dimensional line drawn between the two points occupied by each pair of GPS antennas. The post-processed measurements allow more precise positioning, because most GPS errors affect each receiver nearly equally, and therefore can be cancelled out in the calculations. Differential GPS measurements can also be computed in real time by some GPS receivers if they receive a correction signal using a separate radio receiver, for example in Real Time Kinematic (RTK) surveying or navigation.

                                                The improvement of GPS positioning doesn't require simultaneous measurements of two or more receivers in any case, but can also be done by special use of a single device. In the 1990s when even handheld receivers were quite expensive, some methods of quasi-differential GPS were developed, using the receiver by quick turns of positions or loops of 3-10 survey points.
                                                </p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                <img src={DifferentialGlobalPositionSystemImage} className="img-fluid float-right" alt="DifferentialGlobalPositionSystemImage"/>

                                        </div>
                            </div>
                        </div>          
                </div>

            </div>

    </div>)


}

export default GlobalPositionSystem