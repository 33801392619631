import React from 'react';
import { connect } from 'react-redux' ;
import PropTypes from "prop-types";
const Sample =(props)=>{

    console.log(props.loginStates.alert)

    return(<div>
        <h1>Appsmine Infotech</h1>
    </div>)  
}

// PropTypes.Sample = {
//     loginStates : PropTypes.bool
// }

const mapStateToProps = state =>({
    loginStates : state.webLoginReducer.data
})


export default  connect(mapStateToProps,{})  (Sample)