import React from 'react';

import TowerAlignmentDesignImage from '../../asset/image/category/TowerAlignmentDesign.jpg';

import InnerImage from '../InnerImage';

const TowerAlignmentDesign = ()=>{
    return(<div>
        <InnerImage name="Tower Alignment Design" />     
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                <h4 className="body-heading-title"> Tower Alignment Design </h4><br />
                                    <p className="body-heading-paragraph" >Identification of respective lands that are corridored by the Transmission Line by Transect Walking System aiding with GPS devices along the centre line of the corridor. Preparation of rough alignment sketch by denoting the Survey Numbers of the land that pass through. Based on the sketch, conversion of individual FMB Sketch with Ladder measurements that are corridored by the Transmission Line to be done within the Tolerance Limit in AutoCAD. Tolerance Limit shall be applied as per the Survey of India's Guidelines.
                                    </p>
                                    <p className="body-heading-paragraph" >As per prevailing practices/guidelines, width markings to be carried out on either side from mid-line for entire individual FMB Sketches that pass through the Transmission Line Corridor. Further, entire Individual FMB sketches to be combined for forming alignment Topo Drawing. Profiling of the combined FMB Sketches in the field by providing Control Points at every 2km interval using DGPS Instrument. With Reference to the Control Points, the entire Transmission Line Corridor to be compiled as KML File.
                                        A width of 200 meters on either side from the centre point of the tower alignments (Total width = 400m) to be marked in the Village Topo Map by using AutoCAD software by following guidelines of the Survey of India.
                                    </p>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                    <img src={TowerAlignmentDesignImage}  className="img-fluid float-right" alt="TowerAlignmentDesignImage"/>
                            </div>
                        </div>
                    </div>          
                </div>
            </div>
    </div>)
}

export default TowerAlignmentDesign