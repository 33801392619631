const initialState = {
    loading : true,
    data : [],
    error   : ''
}

const WebContactReducer = (state=initialState,action)=>{

    switch(action.type){

        case 'GETWEBCONTACTSUCCESS':
            return{
                ...state,
                loading : false,
                data : action.payload,
                error : ''
            }
            break;
        case 'GETWEBCONTACTFAIL':
            return{
                ...state,
                loading : false,
                data    : '',
                error   : action.errorMsg
            }
            break;
        case 'UPDATEWEBCONTACTSUCCESS' :
            return{
                ...state,
                loading : false,
                data    : action.payload,
                error   : ''
            }
            break;
        case 'UPDATEWEBCONTACTFAIL' :
            return{
                ...state,
                loading : false,
                data    : '',
                error   : action.errorMsg
            }
            break;
        default :
                return state;

    }    
}

export default WebContactReducer;