import React from 'react';

import ProctorCompactionImage from '../../asset/image/category/ProctorCompaction.jpg' ;

import InnerImage from '../InnerImage';

const ProctorCompaction = ()=>{

    return(<div>
        <InnerImage name="Proctor Compaction Test" /> 
                    <div className="container">                        
                                <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">  
                                            <p className="body-heading-paragraph">The Proctor compaction test is a laboratory method of experimentally determining the optimal moisture content at which a given soil type will become most dense and achieve its maximum dry density. The test is named in honor of Ralph Roscoe Proctor [de], who in 1933 showed that the dry density of a soil for a given compactive effort depends on the amount of water the soil contains during soil compaction.[1] His original test is most commonly referred to as the standard Proctor compaction test; his test was later updated to create the modified Proctor compaction test.
                                                    These laboratory tests generally consist of compacting soil at known moisture content into a cylindrical mould with a collar of standard dimensions of height and diameter using a compactive effort of controlled magnitude. The soil is usually compacted into the mould to a certain number of equal layers, each receiving a number of blows from a standard weighted hammer at a specified height. This process is then repeated for various moisture contents and the dry densities are determined for each. The graphical relationship of the dry density to moisture content is then plotted to establish the compaction curve. The maximum dry density is finally obtained from the peak point of the compaction curve and its corresponding moisture content, also known as the optimal moisture content.
                                            </p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                <img src={ProctorCompactionImage}  className="img-fluid float-right" alt="ProctorCompactionImage"/>
                                        </div>
                                        <div className="row">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <p className="body-heading-paragraph">The testing described is generally consistent with the American Society for Testing and Materials (ASTM) standards, and are similar to the American Association of State Highway and Transportation Officials (AASHTO) standards. Currently, the procedures and equipment details for the standard Proctor compaction test is designated by ASTM D698 and AASHTO T99. Also, the modified Proctor compaction test is designated by ASTM D1557 and AASHTO T180-D.
                                                                    Compaction can be generally defined as the densification of soil by the removal of air and rearrangement of soil particles through the addition of mechanical energy. The energy exerted by compaction forces the soil to fill available voids, and the additional frictional forces between the soil particles improves the mechanical properties of the soil. Because a wide range of particles are needed in order to fill all available voids, well-graded soils tend to compact better than poorly graded soils.
                                                                    The degree of compaction of a soil can be measured by its dry unit weight, γd. When water is added to the soil, it functions as a softening agent on the soil particles, causing them to slide between one another more easily. At first, the dry unit weight after compaction increases as the moisture content (ω) increases, but after the optimum moisture content (ωopt) percentage is exceeded, any added water will result in a reduction in dry unit weight because the pore water pressure (pressure of water in-between each soil particle) will be pushing the soil particles apart, decreasing the friction between them.
                                                            </p>
                                                    </div>
                                        </div>
                                </div>
                    </div>        
        </div>)
}

export default ProctorCompaction;