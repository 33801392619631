import axios from 'axios';

import { GETWEBCONTACTSUCCESS,GETWEBCONTACTFAIL,UPDATEWEBCONTACTSUCCESS,UPDATEWEBCONTACTFAIL } from '../type/Type';


export const WebContactInsert =(formData)=>async dispatch =>{

    const config = {
        headers:{
            'Content-Type' : 'application/json'
        }
    }

    

    axios.post("https://www.sreeragu.com/survey/api/webContact",formData,config).then((response)=>{    

        dispatch({
            type : UPDATEWEBCONTACTSUCCESS,
            payload : response.data
        })
    }).catch((errMsg)=>{

        console.log(errMsg);

        dispatch({
            type : UPDATEWEBCONTACTFAIL,
            error : errMsg.message
        })
    })
}

export const getWebContact = ()=>async dispatch=>{

    const config = {
        headers:{
            'Content-Type' : 'application/json'
        }
    }
    const formData ={
        "action" : "View"
    }

    axios.post("https://www.sreeragu.com/survey/api/webContact",formData,config).then((response)=>{
        dispatch({
            type : GETWEBCONTACTSUCCESS,
            payload : response.data
        })

    }).catch((errMsg)=>{
        dispatch({
            type : GETWEBCONTACTFAIL,
            error : errMsg.message
        })
    })
}

