import React from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import LoginHeader from './LoginHeader';
// import { WebContactInsert,getWebContact } from '../../redux/action';

const MainHeader = ({loginStates})=>{

    // console.log(loginStates);

    // return(<Header />)  

    if (loginStates.message === "Success") 
    {
        return(<LoginHeader />)
       
    }
    else
    {
        return(<Header />)      
    }
}

// const mapStateToProps=(state)=>({
//     loginStates : state.webLoginReducer.data
// });

const mapStateToProps=(state)=>({
    loginStates : state.webLoginReducer.data
})


export default connect(mapStateToProps) (MainHeader)