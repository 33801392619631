import React from 'react';
import { GETWEBFOOTERSUCCESS,GETWEBFOOTERFAIL,UPDATEWEBFOOTERSUCCESS,UPDATEWEBFOOTERFAIL } from '../type/Type';
const initialState = {
    loading : true,
    data : [],
    error   : ''
}
const WebFooterReducer =(state=initialState,action)=>{
    switch(action.type){

        case GETWEBFOOTERSUCCESS :
            //console.log(`Reducer Return values ${ JSON.stringify(action.payload) }`)
            return{
                ...state,
                loading : false,
                data : action.payload,
                error : ''
            }
        break;
        case GETWEBFOOTERFAIL :
            return{
                ...state,
                loading : false,              
                error : action.error
            }
        break;
        case UPDATEWEBFOOTERSUCCESS :
            return{
                ...state,
                loading : false,
                data    : action.payload,
                error   : ''
            }
        break;
        case UPDATEWEBFOOTERFAIL :
            return{
                ...state,
                loading : false,              
                error   : action.error
            }
        break;
        default :
            return state;
    }
}
export default WebFooterReducer;
