import React,{ useState,useEffect,Fragment } from 'react';
import { Link, useNavigate  } from "react-router-dom";
import useContact from '../../pages/common/useContact';
import { webConfig,config } from '../../url/CommonUrl';
import axios from 'axios';
import Loader from '../loader/Loader';



const ContactInsert =()=>{

    const [formData,setFormData] = useState({
        "action" : "Insert",
        "RfId" : "",
        "Address" : "",
        "TelePhoneNo" : "",
        "MobileNo" : "",
        "EmailId" : "",
        "Remarks" : ""
    });

    const [datas,errors] = useContact();
    const navigate = useNavigate();
    const [active,setActive] = useState(true);

    // const history = useNavigate();
    
    useEffect(()=>{     
      //  console.log(datas);
        if (datas.length > 0) {           
            datas.map(data=>{
                setFormData({
                    "Address" : data.Address,
                    "TelePhoneNo" : data.TelePhoneNo,
                    "MobileNo" : data.MobileNo,
                    "EmailId" : data.EmailId,
                    "Remarks" : data.Remarks,
                    "RfId" : data.RfId,
                    "action" : "Insert"
                })
            })
            setActive(false);
        }

    },[datas])

    const onChangeSet =(e)=>setFormData({
        ...formData,
        [e.target.name] : e.target.value
    })

    const submitHandler=(e)=>{
         e.preventDefault();    
         setActive(true);   
         axios.post(`${webConfig}webContact`,formData,config).then((response)=>{           
            if(response.data.alert == "Web Contact Pages Updated"){
                navigate.push("/ContactView");             
            }

         }).catch((errMsg)=>{
            console.log(errMsg);
         })  
    }
    return(<Fragment>
        <h1>CONTACT UPDATE</h1>
        <hr />
        <Loader active={ active } />
           <div className="container">
               <div className="row">
                   <div className="col-md-12">
                        <form onSubmit={ submitHandler }   className="was-validated">
                            <div className="form-group">
                                <label for="uname">TelePhone Number :</label>
                                <input type="text" className="form-control" value={ formData.TelePhoneNo } placeholder="Enter Telephone Number"  onChange={(e)=>onChangeSet(e)} name="TelePhoneNo" id="TelePhoneNo" />
                            </div>
                            <div className="form-group">
                                <label for="uname">Mobile No :</label>
                                <input type="text" className="form-control" value={ formData.MobileNo } onChange={(e)=>onChangeSet(e)} placeholder="Enter Mobile No"  name="MobileNo" id="MobileNo" />
                            </div>
                            <div className="form-group">
                                <label for="uname">Email-Id :</label>
                                <input type="text" className="form-control" value={  formData.EmailId} onChange={(e)=>onChangeSet(e)} placeholder="Enter Email - Id"   name="EmailId" id="EmailId" />
                            </div>
                            <div className="form-group">
                                <label for="uname">Address :</label>
                                <textarea  className="form-control" value={ formData.Address } onChange={(e)=>onChangeSet(e)}  name="Address" id="Address">  </textarea>
                            </div>
                            <div className="form-group">
                                <label for="uname">Remarks :</label>
                                <textarea  className="form-control" value={ formData.Remarks } onChange={(e)=>onChangeSet(e)} name="Remarks" id="Remarks">  </textarea>
                            </div>           
                            <button type="submit" className="btn btn-info"> Update </button>
                        </form>
                   </div>
               </div>
           </div>
    </Fragment>)
}
export default ContactInsert