import { useState,useEffect } from 'react';
import { webConfig,config,formData } from '../../url/CommonUrl';

import axios from 'axios';

const useFooter = ()=>{

    const [datas,setDatas] = useState([]);
    const [errors,setErrors] = useState([]);

    useEffect(()=>{

        axios.post(`${ webConfig }webfooter`,formData,config).then((response)=>{

            setDatas(response.data.data);

        }).catch((errMsg)=>{
            setErrors(errMsg);
        })
    },[])
    return [datas,errors]
}

export default useFooter