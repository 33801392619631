import React from 'react';

const initialState = {
    loading : true,
    data    : [],
    error   : ''
}

const webLoginReducer = (state=initialState,action)=>{

    switch(action.type){
        case 'LOGINSUCCESS':
            return{
                loading : false,
                data : action.payload,
                errors : ''
            }
        case 'LOGINFAIL':
            return{
                loading:false,
                data : '',
                errors : action.error
            }
        case 'LOGOUT':
            return{
                loading : false,
                data : action.payload,
                errors : ''
            }
        default:
         return state
    }
}

export default webLoginReducer;