import React from 'react';

import profileImage from '../../asset/image/profile.jpg';

import InnerImage from '../InnerImage';

const Ugss = ()=>{
    
    return(<div>
        <InnerImage name="UGSS" />      
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                            <h4 className="body-heading-title"> DPR Preparation for UGSS </h4><br />
                                                    <p className="body-heading-paragraph" >A sanitary sewer or foul sewer is an underground pipe or tunnel 
                                                    system for transporting sewage from houses and commercial buildings (but not stormwater) to treatment facilities or disposal. 
                                                    Sanitary sewers are part of an overall system called a sewage system or sewerage. Sewage may be treated to control water 
                                                    pollution before discharge to surface waters. Sanitary sewers serving industrial areas also carry industrial wastewater. 
                                                    Separate sanitary sewer systems are designed to transport sewage alone. In municipalities served by sanitary sewers, 
                                                    separate storm drains may convey surface runoff directly to surface waters. Sanitary sewers are distinguished from combined sewers, 
                                                    which combine sewage with stormwater runoff in one pipe. Sanitary sewer systems are beneficial because they avoid combined sewer overflows.</p>
                                                    <p className="body-heading-paragraph" >Field Survey to be carried out and level details at every 30m should be precisely noted.
                                                    Number of household wastes to be noted for fixing collecting chamber, pipe design to be carried out depending upon the flow, 
                                                    taking into account of the rain water also. Waste water to be delivered to the treatment unit, by conduit under various land profile slope.
                                                    Determining all the land slopes, fixing of pipe as per the land profile, providing lift stations if necessary, designing all the treatment units,
                                                    like screening chamber, grid chamber, primary clarifier, secondary clarifier, aeration tank, activate sludge chamber, disinfectant chamber.</p>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                    <img src={profileImage}  className="img-fluid float-right" alt="profileImage"/>
                                            </div>
                                    </div>
                            </div>         
                    </div>
            </div>
    </div>)
}

export default Ugss;