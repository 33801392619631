import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link,useNavigate  } from 'react-router-dom';
import { logoutStatus } from '../../redux/action/WebLoginAction';
import PropsType from 'prop-types';


const LogOut = ({ logoutStatus })=>{
      const navigate = useNavigate();
      useEffect(()=>{
            logoutStatus();
      },[])
      return navigate('/');
      // return <Redirect to="/" />
}

PropsType.LogOut={
      logoutStatus : PropsType.func.isRequired     
}

const mapStateToProps=(state)=>({
      loginStates : state.webLoginReducer.data
})
  
export default connect(mapStateToProps,{ logoutStatus }) (LogOut)

