import React,{ useState,useEffect,Fragment } from 'react';

import { connect } from 'react-redux';

import { getWebFooter,webFooterInsert } from '../../redux/action/WebFooterAction';

import { Link, useNavigate  } from 'react-router-dom';

import PropsType from 'prop-types';


const LoginFooter =({ getWebFooter,webFooterInsert,getStatus })=>{
    const navigate = useNavigate();
    const [formData,setFormData] = useState({
        "action" : "Insert",
        "RfId" : "",
        "Address" : "",
        "TelePhoneNo" : "",
        "MobileNo" : "",
        "EmailId" : "",
        "Remarks" : ""
    })
    
    useEffect(()=>{

        getWebFooter();
        var datas = getStatus.data

         console.log(datas);
       
        if (getStatus.message == "Success") {           
            datas.map(data=>{
                setFormData({
                    "Address" : data.Address,
                    "TelePhoneNo" : data.TelePhoneNo,
                    "MobileNo" : data.MobileNo,
                    "EmailId" : data.EmailId,
                    "Remarks" : data.Remarks,
                    "RfId" : data.RfId,
                    "action" : "Insert"
                })
            })
        }

    },[getWebFooter,webFooterInsert])

    const onChangeSet =(e)=>setFormData({
        ...formData,
        [e.target.name] : e.target.value
    })

    const submitHandler=(e)=>{
         e.preventDefault();
           webFooterInsert(formData);
        //   console.log(formData);
        // console.log(getStatus);
        return navigate('/LoginFooterView');
        // <Redirect to="/LoginFooterView" />

    }


    return(<Fragment>
        <h1> Login Footer </h1>
        <hr />
           <div className="container">
               <div className="row">
                   <div className="col-md-12">
                        <form onSubmit={ submitHandler }   className="was-validated">
                            <div className="form-group">
                                <label for="uname">TelePhone Number :</label>
                                <input type="text" className="form-control" value={ formData.TelePhoneNo } placeholder="Enter Telephone Number"  onChange={(e)=>onChangeSet(e)} name="TelePhoneNo" id="TelePhoneNo" />
                            </div>
                            <div className="form-group">
                                <label for="uname">Mobile No :</label>
                                <input type="text" className="form-control" value={ formData.MobileNo } onChange={(e)=>onChangeSet(e)} placeholder="Enter Mobile No"  name="MobileNo" id="MobileNo" />
                            </div>
                            <div className="form-group">
                                <label for="uname">Email-Id :</label>
                                <input type="text" className="form-control" value={  formData.EmailId} onChange={(e)=>onChangeSet(e)} placeholder="Enter Email - Id"   name="EmailId" id="EmailId" />
                            </div>
                            <div className="form-group">
                                <label for="uname">Address :</label>
                                <textarea  className="form-control" value={ formData.Address } onChange={(e)=>onChangeSet(e)}  name="Address" id="Address">  </textarea>
                            </div>
                            <div className="form-group">
                                <label for="uname">Remarks :</label>
                                <textarea  className="form-control" value={ formData.Remarks } onChange={(e)=>onChangeSet(e)} name="Remarks" id="Remarks">  </textarea>
                            </div>
                                               
                            <button type="submit" className="btn btn-info"> Update </button>
                        </form>
                   </div>
               </div>
           </div>
    </Fragment>)
}

LoginFooter.propTypes ={
    getWebFooter:PropsType.func.isRequired,
    webFooterInsert:PropsType.func.isRequired
}

const mapStateToProps=(state)=>({
    getStatus : state.WebFooterReducer.data
})

export default connect(mapStateToProps,{ getWebFooter,webFooterInsert }) (LoginFooter)