import { LOGINSUCCESS,LOGINFAIL, LOGOUT } from '../type/Type';
import axios from 'axios';


export const loginStatus = (formData)=>async(dispatch)=>{

    const config = {
        headers:{
            'Content-Type' : 'application/json'
        }
    }
    axios.post("https://www.sreeragu.com/survey/api/mobilelogin",formData,config).then((response)=>{
        dispatch({
            type : LOGINSUCCESS,
            payload : response.data
        })
    }).catch((errMsg)=>{
        dispatch({
            type : LOGINFAIL,
            error : errMsg
        })
    })
}

export const logoutStatus = ()=> async(dispatch)=>{
    dispatch({
        type : LOGOUT,
        payload : [{
            logout : "Yes"
        }]
    })
}