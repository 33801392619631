import React from 'react';

import TotalStationImage from '../../asset/image/category/TotalStation.jpg';

import InnerImage from '../InnerImage';

const TotalStation = ()=>{

    return(<div>
        <InnerImage name="Total Station" />       
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                <h4 className="body-heading-title"> Total Station </h4><br />
                                    <p className="body-heading-paragraph" >A total station (TS) or total station theodolite (TST) is an electronic/optical instrument used for surveying and building construction. It is an electronic transit theodolite integrated with electronic distance measurement (EDM) to measure both vertical and horizontal angles and the slope distance from the instrument to a particular point, and an on-board computer to collect data and perform triangulation calculations.
                                        Robotic or motorized total stations allow the operator to control the instrument from a distance via remote control. This eliminates the need for an assistant staff member as the operator holds the retroreflector and controls the total station from the observed point. These motorized total stations can also be used in automated setups knows as Automated Motorized Total Station (AMTS).
                                        Most total station instruments measure angles by means of electro-optical scanning of extremely precise digital bar-codes etched on rotating glass cylinders or discs within the instrument. The best quality total stations are capable of measuring angles to 0.5 arc-second. Inexpensive "construction grade" total stations can generally measure angles to 5 or 10 arc-seconds.
                                        Measurement of distance is accomplished with a modulated infrared carrier signal, generated by a small solid-state emitter within the instrument's optical path, and reflected by a prism reflector or the object under survey. The modulation pattern in the returning signal is read and interpreted by the computer in the total station. The distance is determined by emitting and receiving multiple frequencies, and determining the integer number of wavelengths to the target for each frequency. Most total stations use purpose-built glass prism (surveying) reflectors for the EDM signal. A typical total station can measure distances up to 1,500 meters (4,900 ft) with an accuracy of about 1.5 millimetres (0.059 in) ± 2 parts per million.
                                        </p>
                                        <p className="body-heading-paragraph" >Reflectorless total stations can measure distances to any object that is reasonably light in colour, up to a few hundred meters. The coordinates of an unknown point relative to a known coordinate can be determined using the total station as long as a direct line of sight can be established between the two points. Angles and distances are measured from the total station to points under survey, and the coordinates (X, Y, and Z; or easting, northing, and elevation) of surveyed points relative to the total station position are calculated using trigonometry and triangulation.
                                        To determine an absolute location, a total station requires line of sight observations and can be set up over a known point or with line of sight to 2 or more points with known location, called free stationing.
                                        For this reason, some total stations also have a Global Navigation Satellite System receiver and do not require a direct line of sight to determine coordinates. However, GNSS measurements may require longer occupation periods and offer relatively poor accuracy in the vertical axis.
                                        Some models include internal electronic data storage to record distance, horizontal angle, and vertical angle measured, while other models are equipped to write these measurements to an external data collector, such as a hand-held computer.
                                        When data is downloaded from a total station onto a computer, application software can be used to compute results and generate a map of the surveyed area. The newest generation of total stations can also show the map on the touch-screen of the instrument immediately after measuring the points.
                                        Total stations are mainly used by land surveyors and civil engineers, either to record features as in topographic surveying or to set out features (such as roads, houses or boundaries). They are also used by archaeologists to record excavations and by police, crime scene investigators, private accident reconstructionist and insurance companies to take measurements of scenes.
                                        </p>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                    <img src={TotalStationImage}  className="img-fluid float-right" alt="TotalStationImage"/>
                            </div>
                        </div>
                    </div>          
                </div>
            </div>
    </div>)

}

export default TotalStation