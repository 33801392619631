import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { galleryView } from '../redux/action/WebGalleryAction';
import PropsType from 'prop-types';
import InnerHtml from './InnerImage';
import {WebGallery} from '../pages/common/useGallery';


const Gallery = ()=>{

     
        const [ imageArray,imageError ] = WebGallery()

        const imageShow = imageArray.map(image=>{

                if(image.IsActive ===1 ){
                        return(<div className="col-sm-6 col-md-4 col-lg-4 col-xl-4" key={ image.RfId } >
                                        <img src={`https://www.sreeragu.com/survey/${ image.Images }`} className="img-thumbnail"  alt="Src"  />
                                </div>) 

                }  
        }) 
       

    return(<div>
            <InnerHtml name="GALLERY" />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">      
                            <div className="row"> 
                                {
                                        imageShow
                                }                                   
                            </div>
                    </div>
                </div>
            </div>
    </div>)
}

export default React.memo(Gallery);