import React from 'react';

import {Link} from 'react-router-dom';

const Header = ()=>{
    return(<div>
                <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12  col-sm-12 col-lg-12 col-xl-12">
                                <nav className="navbar navbar-expand-sm bg-info navbar-dark">
                                    <Link className="navbar-brand" to="/">Sree Ragu</Link>
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div className="collapse navbar-collapse" id="collapsibleNavbar">
                                            <ul className="navbar-nav">
                                                <li className="nav-item navbar-center-align">
                                                    <Link className="nav-link navbar-hyber-link-color" to="/" >HOME</Link>
                                                </li>
                                                 <li className="nav-item navbar-center-align">
                                                    <Link className="nav-link navbar-hyber-link-color" to="/AboutUs">ABOUT US</Link>
                                                </li>
                                                <li className="dropdown nav-item navbar-center-align">
                                                    <a className="dropdown-toggle nav-link navbar-hyber-link-color" data-toggle="dropdown" href="#">DPR PREPRATION
                                                    <span className="caret"></span></a>
                                                    <ul  className="dropdown-menu">
                                                    <li  className="dropdown-item" ><Link to="/ugss">UGSS</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/PipeLineDesign">Pipe Line Design</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/TowerAlignmentDesign">Tower Alignment Design</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/TunnelDesign">Tunnel Design</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/RoadDesign">Road Design</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/MITankDesign">MI Tank Design</Link></li>                                  
                                                    </ul>
                                                </li>                                               
                                                <li className="dropdown nav-item navbar-center-align">
                                                    <a className="dropdown-toggle nav-link navbar-hyber-link-color" data-toggle="dropdown" href="#">SURVEY
                                                    <span className="caret"></span></a>
                                                    <ul className="dropdown-menu">
                                                    <li className="dropdown-item" ><Link to="/ContourSurveying">Contour Surveying</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/HighwaySurveying">Highway Surveying</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/LandAcquisitionWork">Land Acquisition Work</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/LandSurveying">Land Surveying</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/PipeLineSurveying">Pipe Line Surveying</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/TunnelSurveying">Tunnel Surveying</Link></li>                                
                                                    </ul>
                                                </li>

                                                <li className="dropdown nav-item navbar-center-align">
                                                    <a className="dropdown-toggle nav-link navbar-hyber-link-color" data-toggle="dropdown" href="#">EQUIPMENT
                                                    <span className="caret"></span></a>
                                                    <ul  className="dropdown-menu">
                                                    <li  className="dropdown-item" ><Link to="/SoilTesting">Soil Testing</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/GlobalPositionSystem">Differential Global Position System</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/TotalStation">Total Station</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/DroneSurveying">Drone Surveying</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/AugerTractor">Auger Tractor</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/HandGPS">Hand GPS</Link></li>                                
                                                    </ul>
                                                </li>                                              
                                                <li className="dropdown nav-item navbar-center-align">
                                                    <a className="dropdown-toggle nav-link navbar-hyber-link-color" data-toggle="dropdown" href="#">SOLI TEST
                                                    <span className="caret"></span></a>
                                                    <ul  className="dropdown-menu">
                                                    <li  className="dropdown-item" ><Link to="/StandardPenetration">Standard Penetration</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/FieldDensity">Field Density </Link></li>
                                                    <li  className="dropdown-item" ><Link to="/ShearTest">Shear Test (Vane and Direct)</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/ProctorCompaction">Proctor Compaction </Link></li>
                                                    <li  className="dropdown-item" ><Link to="/Permeability">Permeability</Link></li>
                                                    <li  className="dropdown-item" ><Link to="/Consolidation">Consolidation</Link></li>                                
                                                    <li  className="dropdown-item" ><Link to="/GrainSizeAnalysis">Grain Size Analysis</Link></li>                                
                                                    <li  className="dropdown-item" ><Link to="/Consistency">Consistency</Link></li>                                
                                                    <li  className="dropdown-item" ><Link to="/CaliforniaBearingRatio">California Bearing Ratio</Link></li>                                
                                                    </ul>
                                                </li>
                                                <li className="" >
                                                    <Link className="nav-link navbar-hyber-link-color" to="/Gallery">GALLERY</Link>
                                                </li>
                                                <li className="nav-item navbar-center-align">
                                                    <Link className="nav-link navbar-hyber-link-color" to="/Contact">CONTACT</Link>
                                                </li>  
                                            </ul>
                                        </div>  
                                </nav>
                            </div>
                        </div>
                </div>
            <br />
    </div>)
}
export default Header