import React from 'react';

import PermeabilityImage from '../../asset/image/category/Permeability.jpg' ;

import InnerImage from '../InnerImage';

const Permeability = ()=>{

    return(<div>
        <InnerImage name="Permeability Test" />  
                <div className="container">                    
                            <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                                <p className="body-heading-paragraph">The rate of flow of water, under laminar flow conditions, through a unit cross sectional are of soil mass, under unit hydraulic gradient, is defined as coefficient of permeability. Permeability of the soil governs the magnitude of excess pore water pressure built-up in the embankment or cuttings, during consolidation process or when the embankment is ponded by water. The excess pore water pressure in-turn significantly influences the stability of the embankments and indicate the need, or otherwise, of need for special measures (e.g. sandwich construction) to prevent/quickly dissipate excess pore water pressure. Coefficient of permeability is used to assess drainage characteristics of soil, rate of consolidation and to predict rate of settlement of soil bed. The coefficient of permeability is generally determined by two procedures.

                                                </p>                                                
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                    <img src={PermeabilityImage}  className="img-fluid float-right" alt="PermeabilityImage"/>
                                        </div>
                                        <div className="row">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <h4 className="body-heading-title"> Arrangement for Constant Head permeability </h4><br /><br />
                                                            <ul className="background-non-style">
                                                                        <li> a  &nbsp;&nbsp;&nbsp;Permeameter mould.</li>
                                                                        <li> b &nbsp;&nbsp;&nbsp;Compacting equipment (A vibrating Tamper or a Sliding tamper with a tamping foot of 50 mm in diameter) </li>                          
                                                                        <li> c &nbsp;&nbsp;&nbsp;A porous disc or suitable reinforced screen with spring attached to the top.  </li>                          
                                                                        <li> d &nbsp;&nbsp;&nbsp;A suitable water reservoir capable of supplying water to the Permeameter under constant head,</li>                          
                                                                        <li> e &nbsp;&nbsp;&nbsp;Large Funnels: These shall be fitted with special cylindrical spout, 25 mm in diameter for 10.00 mm maximum size particles, and 13 mm in diameter for 2.00 mm maximum size particles. The length of the spout should be greater than the full length of the permeability chamber at least by 160 mm. </li>                          
                                                            </ul>

                                                    </div>
                                        </div>
                            </div>
                </div>

    </div>)

}

export default Permeability;