import React, { Fragment,useEffect } from 'react';
import { connect } from 'react-redux';
import '../../asset/css/style.css';
import SaveWater from '../../asset/image/save-water.jpg';
import Logo from '../../asset/image/logo.jpeg';
import Ragu from '../../asset/image/ragu.jpg';
import Kural from '../../asset/image/kural.jpeg';
import TopHeaderChild from './TopHeaderChild';
import PropTypes from 'prop-types';

const TopHeader = ({frontData}) =>{

     //  console.log(props.frontData);

    //    useEffect(()=>{
    //     if(props.frontData.message === "Success")
    //     {         
    //       const HeaderData =    props.frontData.data.map((front)=>{
   
    //            const LogoImage = front.LogoImage ;
   
    //            const KuralImage = front.KuralImage;
   
    //            const ProfileImage = front.ProfileImage;
   
    //            return(
                   
    //                <div className="row">
    //                    <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5" >
    //                        <img src="https://www.sreeragu.com/survey/File/images/logo.jpeg" className="img-fluid float-left mx-auto d-block logo-image" alt="Save Water" />                   
    //                    </div>
    //                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3"  >
    //                        <div className="row">
    //                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3"  >
    //                                    <img src={  SaveWater } className="img-fluid float-left water-image"   alt="Src"/>
    //                                </div>
    //                                <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9" >
    //                                <p className="quoates-front-page" >" நீர் ஆதாரத்தை பாதுகாப்போம் </p>
    //                                <p className="quoates-front-page-second" > நீர் வாளத்தை பெருக்குவோம் "</p>
    //                                </div>
    //                        </div>
    //                    </div>
    //                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3"  >                   
    //                            <img src={ KuralImage ? KuralImage : Kural } className="img-thumbnail water-image"  alt="Ragu" />
    //                    </div>
    //                    <div className="col-sm-12 col-md-6 col-lg-1 col-xl-1">
    //                        <img src={ ProfileImage ? ProfileImage : Ragu } className="img-thumbnail"  alt="Src" />                        
    //                    </div>
    //                 </div>)
            
           
    //        })
    //     }
    //    })

    
     // console.log(logoImage);

     if(frontData.message === "Success")
     {

        return(<Fragment>
            {
                   frontData.data.map(row=>{
                    return(<TopHeaderChild images = { row } key={ row.RfId } />)
                })     
            }
        </Fragment>)

     }
     else
     {
        return(<div>
            <TopHeaderChild />
      </div>)

     }   
}

PropTypes.TopHeader ={

}

const mapStateToProps = (state)=>({
    frontData : state.webreducer.data
})

export default connect(mapStateToProps) (TopHeader)