import React from 'react';
import Carousel from 'react-material-ui-carousel'
import {WebScroll} from '../common/useScroll';

function Example()
{
    
    const [Datas,Error] = WebScroll();

    console.log(Datas);

    return (
        <Carousel>
            {
                Datas.map( (item, i) => <img src={ `https://www.sreeragu.com/survey/${item.Images}`} className="img-thumbnail" height="300" width="450" alt="Src" background-color="black" />)
            }
        </Carousel>
    )
}


export default Example