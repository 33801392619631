import React from 'react';

import GrainSizeAnalysisImage from '../../asset/image/category/GrainSizeAnalysis.jpg' ;

import InnerImage from '../InnerImage';

const GrainSizeAnalysis = ()=>{
    return(<div>
        <InnerImage name="Grain Size Analysis" />        
                <div className="container">                    
                            <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">

                                            <p className="body-heading-paragraph">A sieve analysis (or gradation test) is a practice or procedure used (commonly used in civil engineering) to assess the particle size distribution (also called gradation) of a granular material by allowing the material to pass through a series of sieves of progressively smaller mesh size and weighing the amount of material that is stopped by each sieve as a fraction of the whole mass. The size distribution is often of critical importance to the way the material performs in use. A sieve analysis can be performed on any type of non-organic or organic granular materials including sands, crushed rock, clays, granite, feldspars, coal, soil, a wide range of manufactured powders, grain and seeds, down to a minimum size depending on the exact method. Being such a simple technique of particle sizing, it is probably the most common.
                                                A gradation test is performed on a sample of aggregate in a laboratory. A typical sieve analysis involves a nested column of sieves with wire mesh cloth (screen). See the separate Mesh (scale) page for details of sieve sizing.
                                            </p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                <img src={GrainSizeAnalysisImage}  className="img-fluid float-right" alt="GrainSizeAnalysisImage"/>
                                        </div>
                                        <div className="row">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">                                            
                                                            <p className="body-heading-paragraph">A representative weighed sample is poured into the top sieve which has the largest screen openings. Each lower sieve in the column has smaller openings than the one above. At the base is a round pan, called the receiver.
                                                                    The column is typically placed in a mechanical shaker. The shaker shakes the column, usually for some fixed amount of time. After the shaking is complete the material on each sieve is weighed. The mass of the sample of each sieve is then divided by the total mass to give a percentage retained on each sieve. The size of the average particle on each sieve is then analysed to get a cut-off point or specific size range, which is then captured on a screen.
                                                                    The results of this test are used to describe the properties of the aggregate and to see if it is appropriate for various civil engineering purposes such as selecting the appropriate aggregate for concrete mixes and asphalt mixes as well as sizing of water production well screens.
                                                                    The results of this test are provided in graphical form to identify the type of gradation of the aggregate. The complete procedure for this test is outlined in the American Society for Testing and Materials (ASTM) C 136[2] and the American Association and State Highway and Transportation Officials (AASHTO) T 27[3]
                                                                    A suitable sieve size for the aggregate underneath the nest of sieves to collect the aggregate that passes through the smallest. The entire nest is then agitated, and the material whose diameter is smaller than the mesh opening pass through the sieves. After the aggregate reaches the pan, the amount of material retained in each sieve is then weighed.[4]
                                                            .</p>                                                    
                                                    </div>
                                        </div>
                            </div>
                </div>
    </div>)
}

export default GrainSizeAnalysis;