import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getWebContact } from '../redux/action/WebContactAction';
import PropsType from 'prop-types';
import useContact from '../pages/common/useContact';


 
import InnerHtml from './InnerImage';



const Contact = ()=>{

    const [webContact,webContactError] = useContact()

    
    const contactList = webContact.map(web=>{
        return(  <div className="col-md-4" key={ web.RfId } >
        <h5 className="text-uppercase mb-3">Address</h5>
       { web.Address.split("<br />").map((i,key) => {
                                                        return <p key={key}>{i}</p>;
        }) }
        
        <h5 className="text-uppercase mb-3">Email Us At</h5>
        <p className="mb-5"><a href="mailto:srcragu@gmail.com">{ web.EmailId }</a> <br /> 
        <a href="www.sreeragu.com">www.sreeragu.com	</a></p>                            
        {/* <h5 className="text-uppercase mb-3">Call Us</h5>        
        <p className="mb-5"> { web.TelePhoneNo.split("<br />").map((i,key) => {
                                                        return <p key={key}>{i}</p>;
        }) } </p> */}
    </div>)
    })
    

    return(<div>          
            <InnerHtml name="Contact Us" />
            <section className="section">
            <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3886.968249374822!2d80.27649199999999!3d13.037693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDAyJzE1LjciTiA4MMKwMTYnMzUuNCJF!5e0!3m2!1sen!2sin!4v1715532204197!5m2!1sen!2sin" className="contactUs-Image-map"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.49433006431966!2d77.71776391009318!3d11.341336670892385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba96f39406632b9%3A0x6847b9f9a5a39a37!2sSRC+hospital!5e0!3m2!1sen!2sin!4v1547015317474" className="contactUs-Image-map" ></iframe> */}
                        </div>
                        <div className="col-md-1"></div>
                        { contactList }
                    </div>
                </div>
            </section>            
            
    </div>)
}

// PropsType.Contact={
//     getWebContact : PropsType.func.isRequired
// }

// const mapStateToProps = (state)=>({
//     getContactData : state.WebContactReducer.data
// })
// export default connect(mapStateToProps,{ getWebContact }) (Contact)

export default React.memo(Contact);