import { useState,useEffect } from 'react';
import { webConfig,config,formData } from '../../url/CommonUrl';

import axios from 'axios';

const useContact = ()=>{

    const [datas,setDatas] = useState([]);
    const [errors,setErrors] = useState([]);

    useEffect(()=>{
        axios.post(`${ webConfig }webContact`,formData,config).then((response)=>{

            setDatas(response.data.data);

        }).catch((errMsg)=>{
            setErrors(errMsg);
        })
    },[])
    return [datas,errors]
}
export default useContact