import React from 'react';

import surveyImage from '../../asset/image/survey-1.jpg' ;

import InnerImage from '../InnerImage';

const ContourSurveying =()=>{
    return(<div>
        <InnerImage name="Contour Surveying" />
                <div className="container">                
                        <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <p className="body-heading-paragraph">Highway surveying is a specialized type of land surveying generally conducted for government agencies during the planning stages of a highway development project.
                                        During the construction process, a highway construction survey can ensure that progress is occurring as it should, and that the highway is located precisely where it should be. 
                                        After the highway is built, a highway survey can be used to provide an accurate layout of roadways, utilities, storm drainage systems, overhead wires, nearby buildings, and other 
                                        features of the landscape.A construction surveyor is often involved in the placement process for the highway. This requires a survey of the existing terrain to find the best route. 
                                        Highway construction surveys are particularly concerned with areas where dirt must be moved, noting to what level and grade. Often the goal of this survey is to determine the appropriate 
                                        route where the least amount of land will need to be moved. An engineer can use the survey data to determine where the dirt should be moved to reduce the total amount of dirt relocation. 
                                        Not only do surveyors determine how the land must be prepared before the highway can be constructed, but they also assist engineers in the mapping of optimal routes. This includes grading 
                                        (slopes), curves and the creation of tunnels or other features along the highway route. It is necessary to understand the geographic restrictions and the layout of an area before attempting 
                                        to determine the best route for placement of the highway. Because of the natural landscape, the presence existing buildings and other features, few highways are able to run perfectly straight 
                                        along their entire length. One of a surveyor’s many jobs during highway construction is to aid in the mapping of this route. The most challenging part of highway planning is often the necessity 
                                        of bridges or tunnels. Surveyors provide input on their location, and also monitor the highway construction process to ensure that they are located correctly.
                                        </p>                                
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <img src={surveyImage}  className="img-fluid float-right" alt="surveyImage"/>
                                </div>
                        </div>
                </div>
    </div>)

}

export default ContourSurveying;