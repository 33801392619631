import React,{ useState,useEffect,useRef } from 'react';
import useFrontPage from '../common/useFrontPage';
import { useNavigate } from "react-router-dom";
import { webConfig } from '../../url/CommonUrl';
import axios from 'axios';
import Loader from '../loader/Loader';
const FrontPage = ()=>{
    
  const [frontPages,frontErrorPages] =  useFrontPage();

  const el             = useRef();

  const [file, setFile] = useState(''); 

  const history = useNavigate();

  const [active,setActive] = useState(true);

  const [logoImage,setLogoImages] = useState('');

  const [kuramImages,setKuralImages] = useState('');

  const [profileImage,setProfileImage] = useState('');

  const [imageDownload,setImageDownload] = useState('');

  const [scrollImage,setScrollImage] = useState('');

  const [formData,setFormData] = useState({
    "RfId": '',
    "LogoImage": "",
    "KuralImage": "",
    "ProfileImage": "",
    // "Title": "",
    // "ImageDownload": "",
    // "ScrollingImage": "",
    // "ScrollingContent": "",
    "IsActive": 1,
    "Remarks": ""
});

useEffect(()=>{         
            frontPages.length && frontPages.map(data=>{
                setFormData({
                  "RfId": data.RfId,  
                //   "Title": data.Title,   
                //   "ScrollingContent": data.ScrollingContent,
                  "IsActive": 1,
                  "Remarks": ""
                })
              })    
              
        frontPages.length &&   setActive(false);

 },[frontPages])

const onChangeSet =(e)=>setFormData({
    ...formData,
    [e.target.name] : e.target.value
})

const handleChange  = (e,action)=>{

    const file = e.target.files[0]; // accesing file
    switch(action){
        case "LogoImage" :
              setLogoImages(file);
            break;
        case "KuralImage" :
              setKuralImages(file);
            break;
        case "ProfileImage" :
            setProfileImage(file);
            break;
        case "ImageDownload" :
            setImageDownload(file);
            break;
        case "ScrollingImage" :
            setScrollImage(file);
            break;
        default :
           return null;
    }  
}

const onSubmit =async(e)=>{

    e.preventDefault();       

    const body   = new FormData();

    setActive(true);   

     body.append('LogoImage',logoImage);

     body.append('KuralImage',kuramImages);

     body.append('ProfileImage',profileImage);

    //  body.append('ImageDownload',imageDownload);

    //  body.append('ScrollingImage',scrollImage);

    //  body.append('Title',formData.Title);

    //  body.append('ScrollingContent',formData.ScrollingContent);
    
     body.append('Remarks',formData.Remarks);

    body.append('action','Insert');
   
    body.append('RfId',1);
   
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'              
        }
    }        
    
    // console.log(body);

    axios.post(`${ webConfig }frontPage/front-page`,body,config).then((response)=>{

       //  console.log(response.data)  
        if(response.data.alert == "Web Front pages Updated"){
            history.push("/FrontPageView");       
        }           

    }).catch((errMsg)=>{

            console.log(errMsg);

    })
}  
    return(<div>
        <h1> Front Page Update </h1>
        <hr />
        <Loader active={ active } />
           <div className="container">
               <div className="row">
                   <div className="col-md-2"></div>
                   <div className="col-md-6 col-lg-6">
                        <form onSubmit={ onSubmit } className="was-validated">                            
                            <div className="form-group">
                                <label for="uname">Logo Image :</label>
                                <input type="file" className="form-control"  onChange={ (e)=>handleChange(e,'LogoImage') } name="LogoImage" id="LogoImage" />
                            </div>

                            <div className="form-group">
                                <label for="uname">Kural Image :</label>
                                <input type="file" className="form-control" onChange={ (e)=>handleChange(e,'KuralImage') }  name="KuralImage" id="KuralImage" />
                            </div>

                            <div className="form-group">
                                <label for="uname">Profile Image :</label>
                                <input type="file" className="form-control" onChange={ (e)=>handleChange(e,'ProfileImage') }  name="ProfileImage" id="ProfileImage" />
                            </div>

                            {/* <div className="form-group">
                                <label for="uname">Title Content :</label>
                                <textarea  className="form-control" value={ formData.Title } name="Title" id="Title"  onChange={(e)=>onChangeSet(e)}></textarea>
                            </div>
                            
                            <div className="form-group">
                                <label for="uname">Images Download :</label>
                                <input type="file" className="form-control" onChange={ (e)=>handleChange(e,'ImageDownload') }   name="ImageDownload" id="ImageDownload" />
                            </div>

                            <div className="form-group">
                                <label for="uname">Scroll Images :</label>
                                <input type="file" className="form-control" onChange={ (e)=>handleChange(e,'ScrollingImage') }  name="ScrollingImage" id="ScrollingImage" />
                            </div>

                            <div className="form-group">
                                <label for="uname">Scrolling Content :</label>
                                <textarea  className="form-control" value={ formData.ScrollingContent } name="ScrollingContent" id="ScrollingContent"  onChange={(e)=>onChangeSet(e)}>  </textarea>
                            </div>     */}

                            <div className="form-group">
                                <label for="uname">Remarks :</label>
                                <textarea  className="form-control" value={ formData.Remarks } name="Remarks" id="Remarks"  onChange={(e)=>onChangeSet(e)}>  </textarea>
                            </div>                                
                            <button type="submit" className="btn btn-info"> Update </button>
                        </form> 
                   </div>
               </div>
           </div>
    </div>)
}
export default React.memo(FrontPage) 