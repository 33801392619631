import React,{Fragment, useState} from 'react';
import { connect } from 'react-redux';
import { loginStatus } from '../../redux/action/WebLoginAction';
import { Link,useNavigate  } from 'react-router-dom';
import PropTypes from 'prop-types'; 
import Login from '../../asset/image/login.jpg';
import Loader from '../../pages/loader/Loader';

const Form =({ loginStatus,loginState })=>{
    const navigate = useNavigate();
    const [active,setActive] = useState(false);

    const [formData,setFormData] = useState({
        "Mobile_No" : "",
        "UserPassword" : "",
        "action" : "Login"
    })

    const onChnage = (e) =>setFormData({ 
        ...formData,
        [e.target.name] : e.target.value
    })

    const submitHandler = (e)=>{
        e.preventDefault();
        // console.log(formData);
        loginStatus(formData);
        setActive(true);
    }

    // console.log(loginState);

    if(loginState.message === "Success"){
        // setActive(false);
       return navigate('/LoginHeader');
       // return <Redirect to="/LoginHeader" />
        
    }

    return(<Fragment>
            <Loader active={ active } />
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <div className="jumbotron index-left-bar">
                        <h4 className="home-page-left-title" > Customer Login </h4>
                        <form onSubmit={ submitHandler }  className="was-validated">
                            <div className="form-group">
                                <label for="uname">Username:</label>
                                <input type="text" className="form-control"  value={ formData.Mobile_No }  placeholder="Enter Mobile_No" name="Mobile_No" onChange={ (e)=>onChnage(e) } />
                            </div>
                            <div className="form-group">
                                <label for="pwd">Password:</label>
                                <input type="password" className="form-control" value= { formData.UserPassword } placeholder="Enter password" name="UserPassword" required onChange={ (e)=>onChnage(e) }  />                           
                            </div>                      
                            <button type="submit" className="btn btn-info">Login</button>
                        </form>
                    </div>                         
                    <div className="jumbotron index-left-bar">
                        <h4 className="home-page-left-title">Organization Chart <a href="https://www.sreeragu.com/src/"> <img src={ Login } height="25px" width="25px" /></a></h4>
                        <h5>Er.T.Ragupathi </h5>                         
                    </div>
            </div>
    </Fragment>)


}

PropTypes.Form = {
    loginStatus : PropTypes.func.isRequired,
    loginState  : PropTypes.bool
}

const mapStateToProps = (state)=>({
    loginState : state.webLoginReducer.data
})

export default connect(mapStateToProps,{loginStatus})(Form)