import React from 'react';

const InnerImage = (props)=>{

    return(<div>
                <section className="inner-page">
                    <div className="slider-item py-5 background-image-set">        
                        <div className="container">
                            <div className="row slider-text align-items-center justify-content-center text-center">
                                <div className="col-md-7 col-sm-12 element-animate">
                                    <h1 className="text-white">{ props.name }</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    </div>)



}

export default InnerImage;