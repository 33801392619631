import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { createTheme  } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import useFrontContent from '../../common/useFrontContent';
import Loader from '../../loader/Loader';

const useStyles = createTheme ({
  table: {
    minWidth: 600,
    border: "2px solid blue",
    boxShadow: "5px 10px #999999",
  },
});

const FrontContentViewPage = () => {
  const classes = useStyles();

  const history = useNavigate();

  const [frontContentView, frontContentError ] = useFrontContent();
  
  
  const contentUpdate = () =>{

    history.push("/FrontContentUpdate");       
}
 
 
  return (<React.Fragment>
    <Loader active={ frontContentView.length ? false : true } />
      <Grid
      item
      container
      xs={12}
      sm={12}
      lg={12}
      md={12}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid lg={2} md={12}></Grid>

      <Grid
        item
        container
        xs={12}
        lg={8}
        md={8}
        spacing={2}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="caption table">
            <caption>SRC FRONT CONTENT</caption>
            <TableHead>
              <TableRow>
                <TableCell align="right">SNo</TableCell>
                <TableCell align="right">Left Content</TableCell>
                <TableCell align="right">Bottom Content</TableCell>
                <TableCell align="right">Bottom Below</TableCell>                         
                <TableCell align="right">Update</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {frontContentView.map((content) => (
                <TableRow key={content.RfId}>
                  <TableCell align="right">{ content.RfId }</TableCell>                  
                  <TableCell align="right"><p>{content.LeftContent}</p></TableCell>                
                  <TableCell align="right"><p>{content.BottomContent}</p></TableCell>                 
                  <TableCell align="right"><p>{content.frontBottom}</p></TableCell> 
                  <TableCell align="right">
                    <Button variant="contained" color="primary" onClick={ contentUpdate } >
                        Update
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={2} md={12}></Grid>
    </Grid>
  </React.Fragment>
  );
};

export default React.memo(FrontContentViewPage);
