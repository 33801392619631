export const WEBIMAGES                          =  'WEBIMAGES';

export const WEBERROR                           =  'WEBERROR';

export const LOGINSUCCESS                       =  'LOGINSUCCESS';

export const LOGINFAIL                          =  'LOGINFAIL';

export const LOGOUT                             =  'LOGOUT';

export const WEBGALLERYSUCCESS                  =  'WEBGALLERYSUCCESS';

export const WEBGALLERYFAIL                     =  'WEBGALLERYFAIL';

export const GETWEBCONTACTSUCCESS               =  'GETWEBCONTACTSUCCESS';

export const GETWEBCONTACTFAIL                  =  'GETWEBCONTACTFAIL';

export const UPDATEWEBCONTACTSUCCESS            =  'UPDATEWEBCONTACTSUCCESS';

export const UPDATEWEBCONTACTFAIL               =  'UPDATEWEBCONTACTFAIL';

export const GETWEBFOOTERSUCCESS                =  'GETWEBFOOTERSUCCESS';

export const GETWEBFOOTERFAIL                   =   'GETWEBFOOTERFAIL';

export const UPDATEWEBFOOTERSUCCESS             =   'UPDATEWEBFOOTERSUCCESS';

export const UPDATEWEBFOOTERFAIL                =   'UPDATEWEBFOOTERFAIL';

export const GALLERYINSERT                      =   'GALLERYINSERT';

export const GALLERYUPDATE                      =   'GALLERYUPDATE';

export const GALLERYDELETE                      =   'GALLERYDELETE';

export const GALLERYVIEW                        =   'GALLERYVIEW';

export const GALLERYERROR                       =   'GALLERYERROR';