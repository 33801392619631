import React from 'react';

import surveyFourImage from '../../asset/image/survey-5.jpg' ;

import InnerImage from '../InnerImage';

const LandSurveying =()=>{

    return(<div>
        <InnerImage name="Land Surveying" /> 
                    <div className="container">
                                <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <p className="body-heading-paragraph">Surveying or land surveying is the technique, profession, and science of determining the terrestrial or three-dimensional positions of 
                                                points and the distances and angles between them. A land surveying professional is called a land surveyor. These points are usually on the surface of the Earth, 
                                                and they are often used to establish maps and boundaries for ownership, locations, such as building corners or the surface location of subsurface features, or other purposes required
                                                by government or civil law, such as property sales. Surveyors work with elements of geometry, trigonometry, regression analysis, physics, engineering, metrology, programming languages,
                                                and the law. They use equipment, such as total stations, robotic total stations, theodolites, GPS receivers, retro reflectors, 3D scanners, radios, handheld tablets, digital levels, 
                                                subsurface locators, drones, GIS, and surveying software. Surveying has been an element in the development of the human environment since the beginning of recorded history. 
                                                The planning and execution of most forms of construction require it. It is also used in transport, communications, mapping, and the definition of legal boundaries for land ownership.
                                               </p>                                        
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <img src={surveyFourImage}  className="img-fluid float-right" alt="surveyFourImage"/>  
                                        </div>
                                </div>
                    </div>
    </div>)

}

export default LandSurveying;