import React,{ useState,useEffect,Fragment } from 'react';
import { Link, useNavigate } from "react-router-dom";
import useFrontContent from '../../common/useFrontContent';
import { webConfig,config } from '../../../url/CommonUrl';
import axios from 'axios';
import Loader from '../../loader/Loader';


const FrontContentUpdate =()=>{

    const [formData,setFormData] = useState({
        "action" : "Insert",
        "RfId" : "",
        "LeftContent" : "",
        "BottomContent" : "",       
        "frontBottom" : "",       
        "Remarks" : ""
    });

    const [datas,errors] = useFrontContent();
    const navigate = useNavigate();
    const [active,setActive] = useState(true);

    // const history = useHistory();
    
    useEffect(()=>{     
       console.log(datas);
        if (datas.length > 0) {           
            datas.map(data=>{
                setFormData({
                    "LeftContent" : data.LeftContent,
                    "BottomContent" : data.BottomContent,                   
                    "frontBottom" : data.frontBottom,                   
                    "RfId" : data.RfId,
                    "action" : "Insert"
                })
            })

        setActive(false);
        }

    },[datas])

    const onChangeSet =(e)=>setFormData({
        ...formData,
        [e.target.name] : e.target.value
    })

    const submitHandler=(e)=>{
         e.preventDefault();
         setActive(true);   
        // console.log("karthi");
         axios.post(`${webConfig}webFrontContent`,formData,config).then((response)=>{
             // console.log(response);
            if(response.data.alert == "Web Front Content Updated"){
                  navigate.push("/FrontContentView");             
            }

         }).catch((errMsg)=>{
            console.log(errMsg);
         })  
    }
    return(<Fragment>
        <h1> Front Page Content Update </h1>
        <Loader active={ active } />
        <hr />
           <div className="container">
               <div className="row">
                   <div className="col-md-12">
                        <form onSubmit={ submitHandler }   className="was-validated">                            
                            <div className="form-group">
                                <label for="uname">Left Content :</label>
                                <textarea  className="form-control" value={ formData.LeftContent } onChange={(e)=>onChangeSet(e)}  name="LeftContent" id="LeftContent" cols="12" rows="12" >  </textarea>
                            </div>
                            <div className="form-group">
                                <label for="uname">Bottom Content :</label>
                                <textarea  className="form-control" value={ formData.BottomContent } onChange={(e)=>onChangeSet(e)}  name="BottomContent" id="BottomContent" cols="12" rows="12">  </textarea>
                            </div>
                            <div className="form-group">
                                <label for="uname">Bottom Below :</label>
                                <textarea  className="form-control" value={ formData.frontBottom } onChange={(e)=>onChangeSet(e)}  name="frontBottom" id="frontBottom" cols="12" rows="12">  </textarea>
                            </div>
                            <div className="form-group">
                                <label for="uname">Remarks :</label>
                                <textarea  className="form-control" value={ formData.Remarks } onChange={(e)=>onChangeSet(e)} name="Remarks" id="Remarks">  </textarea>
                            </div>           
                            <button type="submit" className="btn btn-info"> Update </button>
                        </form>
                   </div>
               </div>
           </div>
    </Fragment>)
}
export default FrontContentUpdate