import React from 'react';

import DroneSurveyingImage from '../../asset/image/category/DroneSurveying.jpg';

import InnerImage from '../InnerImage';

const DroneSurveying = ()=>{


    return(<div>
        <InnerImage name="Total Station" />      

        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                        <h4 className="body-heading-title"> Drone Surveying </h4><br />
                                        <p className="body-heading-paragraph" >A drone survey refers to the use of a drone, or unmanned aerial vehicle (UAV), to capture aerial data with downward-facing sensors, such as RGB or multispectral cameras, and LIDAR payloads. During a drone survey with an RGB camera, the ground is photographed several times from different angles, and each image is tagged with coordinates.
                                            Survey drones generate high-resolution orthomosaics and detailed 3D models of areas where low-quality, outdated or even no data, are available. They thus enable high-accuracy cadastral maps to be produced quickly and easily, even in complex or difficult to access environments. Surveyors can also extract features from the images, such as signs, curbs, road markers, fire hydrants and drains.
                                            From this data, a photogrammetry software can create geo-referenced Ortho mosaics, elevation models or 3D models of the project area. These maps can also be used to extract information such as highly-accurate distances or volumetric measurements.
                                        Unlike manned aircraft or satellite imagery, drones can fly at a much lower altitude, making the generation of high-resolution, high-accuracy data, much faster, less expensive and independent of atmospheric conditions such as cloud cover.
                                            One drone flight produces thousands of measurements, which can be represented in different formats (orthomosaic, point cloud, DTM, DSM, contour lines, etc). Each pixel of the produced map or point of the 3D model contains 3D geo-data.
                                            An aerial mapping drone can take off and fly almost anywhere. You are no longer limited by unreachable areas, unsafe steep slopes or harsh terrain unsuitable for traditional measuring tools. You do not need to close down highways or train tracks. In fact, you can capture data during operation without an organizational overhead.
                                        </p>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                        <img src={DroneSurveyingImage}   className="img-fluid float-right" alt="DroneSurveyingImage"/>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <h4 className="body-heading-title"> Stockpile volumetric measurements </h4><br />
                                                                <p className="body-heading-paragraph" >With 3D mapping software, it is also possible to obtain volumetric measurements from the very same images. This fast and inexpensive method of volume measurement is particularly useful to calculate stocks in mines and quarries for inventory or monitoring purposes.
                                        With a drone, surveyors can capture many more topographic data points, hence more accurate volume measurements. They can also do this in a much safer way than if they had to manually capture the data by going up and down a stockpile. Since drones are capturing the data from above, operations on site won’t be interrupted. The short acquisition time enables capturing a site snapshot at a specific point in time.
                                        </p>
                                        <h4 className="body-heading-title"> Slope monitoring </h4><br />
                                                                <p className="body-heading-paragraph" >With automated GIS analysis, it is possible to extract slope measurements from DTMs and DSMs generated by drone imagery. Knowing the steepness of the ground’s surface, the areas can be classNameified and used for slope monitoring purposes, including landslide mitigation and prevention.
                                        With orthomosaics taken at different times, it is possible to detect changes in earth movement and to measure its velocity. This data can help predict landslides and prevent potential damage to roads, railways and bridges.
                                        Compared to traditional monitoring techniques, where sensors are placed on single points, drones enable more comprehensive data collection. Drones with PPK capability, which do not require laying out of multiple GCPs, are optimal for this application, since these areas are often hard to reach or even dangerous.
                                        The development of increasingly dense and complex urban areas requires intensive planning and therefore time-consuming and expensive data collection. Thanks to drones, urban planners can collect large amounts of up-to-date data in a short period of time and with far less staff. The images produced in this way allow planners to examine the existing social and environmental conditions of the sites and consider the impact of different scenarios.
                                        </p>
                                </div>
                        </div>
                    </div>         
            </div>
        </div>
    </div>)

}

export default DroneSurveying;