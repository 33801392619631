import React from 'react';

import { Link } from 'react-router-dom';

const LoginHeader = ()=>{

        return(<div>
            <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12  col-md-12 col-lg-12 col-xl-12">
                            <nav className="navbar navbar-expand-sm bg-info navbar-dark">
                                <Link className="navbar-brand" to="/">Sree Ragu</Link>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                                        <ul className="navbar-nav">
                                            <li className="nav-item navbar-center-align">
                                                <Link className="nav-link navbar-hyber-link-color" to="/" >HOME</Link>
                                            </li>                                      
                                            <li className="dropdown nav-item navbar-center-align">
                                                <a className="dropdown-toggle nav-link navbar-hyber-link-color" data-toggle="dropdown" href="#">FRONT PAGE
                                                <span className="caret"></span></a>
                                                <ul  className="dropdown-menu">
                                                <li  className="dropdown-item" ><Link to="/FrontPageView">Front page</Link></li>
                                                <li  className="dropdown-item" ><Link to="/frontContentView">Front Content</Link></li>
                                                <li  className="dropdown-item" ><Link to="/ScrollPageView">Scroll Images</Link></li>
                                                <li  className="dropdown-item" ><Link to="/ScrollPageInsert">Scroll Images Add</Link></li>
                                                {/* <li  className="dropdown-item" ><Link to="/PipeLineDesign">Pipe Line Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/TowerAlignmentDesign">Tower Alignment Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/TunnelDesign">Tunnel Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/RoadDesign">Road Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/MITankDesign">MI Tank Design</Link></li>                                   */}
                                                </ul>
                                            </li> 
                                            <li className="dropdown nav-item navbar-center-align">
                                                <a className="dropdown-toggle nav-link navbar-hyber-link-color" data-toggle="dropdown" href="#">Footer
                                                <span className="caret"></span></a>
                                                <ul  className="dropdown-menu">
                                                <li  className="dropdown-item" ><Link to="/FooterView">Footer</Link></li>
                                                {/* <li  className="dropdown-item" ><Link to="/PipeLineDesign">Pipe Line Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/TowerAlignmentDesign">Tower Alignment Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/TunnelDesign">Tunnel Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/RoadDesign">Road Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/MITankDesign">MI Tank Design</Link></li>                                   */}
                                                </ul>
                                            </li> 
                                            <li className="dropdown nav-item navbar-center-align">
                                                <a className="dropdown-toggle nav-link navbar-hyber-link-color" data-toggle="dropdown" href="#">Contact
                                                <span className="caret"></span></a>
                                                <ul  className="dropdown-menu">
                                                <li  className="dropdown-item" ><Link to="/ContactView">Contact</Link></li>
                                                {/* <li  className="dropdown-item" ><Link to="/PipeLineDesign">Pipe Line Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/TowerAlignmentDesign">Tower Alignment Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/TunnelDesign">Tunnel Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/RoadDesign">Road Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/MITankDesign">MI Tank Design</Link></li>                                   */}
                                                </ul>
                                            </li> 
                                            <li className="dropdown nav-item navbar-center-align">
                                                <a className="dropdown-toggle nav-link navbar-hyber-link-color" data-toggle="dropdown" href="#">Gallery
                                                <span className="caret"></span></a>
                                                <ul  className="dropdown-menu">
                                                <li  className="dropdown-item" ><Link to="/GalleryView">Gallery</Link></li>
                                                <li  className="dropdown-item" ><Link to="/GalleryInsert">Gallery Insert</Link></li>
                                                {/* <li  className="dropdown-item" ><Link to="/PipeLineDesign">Pipe Line Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/TowerAlignmentDesign">Tower Alignment Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/TunnelDesign">Tunnel Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/RoadDesign">Road Design</Link></li>
                                                <li  className="dropdown-item" ><Link to="/MITankDesign">MI Tank Design</Link></li>                                   */}
                                                </ul>
                                            </li> 
                                            <li className="dropdown nav-item navbar-center-align">
                                                    <Link className="navbar-brand" to="/Logout">Log Out</Link>                                 
                                            </li> 
                                        </ul>
                                    </div>  
                            </nav>
                        </div>
                    </div>
            </div>
        <br />
    </div>)    
}

export default LoginHeader