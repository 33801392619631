import { combineReducers } from 'redux';

import webreducer from './reducer/WebReducer';

import webLoginReducer from './reducer/WebLoginReducer';

import WebContactReducer from './reducer/WebContactReducer';

import WebFooterReducer from './reducer/WebFooterReducer';

import WebGalleryReducer from './reducer/WebGalleryReducer';

export default combineReducers({
    WebFooterReducer,webreducer,webLoginReducer,WebContactReducer,WebGalleryReducer
})


