import React from 'react';

import PipeLineDesignImage from '../../asset/image/category/PipeLineDesign.jpg';

import InnerImage from '../InnerImage';

const PipeLineDesign =()=>{

    return(<div>
        <InnerImage  name="Pipe Line Design" />      
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                        <h4 className="body-heading-title"> DPR for Pipe Line Design</h4><br />
                                        <p className="body-heading-paragraph" >Preferably stationary bank/appropriate RCC Intake for river as source shall be provided in case of surface source with approach required. Capacity of intake shall be provided for stipulated time (depending upon the capacity) of the total water demand. Diameter/size shall be fixed in such a way to accommodate maximum number of pumps in row with clear spacing in between, depending of the discharge to be designed. Considering the future requirement and expansion from time to time space for additional row of pumps and sluice valve with spindles shall be provided. Depth of intake well shall be decided in such a way that floor of the pump house shall be above HFL (height depend on the flow condition). Provision of siltation chamber shall be made in the bottom of intake well. As in case of reservoir as source, provision of head up/diversion weir shall be considered along - with appropriate intake system. Clear head room of pump house over intake shall not less than 6m. Suitable manually operated gantry shall be provided.
                                            Designing of Pump Room considering the induction load of the pump (including stand by pumps) to design base slab for placing the pumps, design of girder, and all other elements.
                                            Suitable water pumps shall be provided 50% standby ( at least minimum one unit additional) arrangement (number of pumps shall be selected in such a manner to ensure optimal running considering required water demands over the period) including installation and testing commissioning in accordance with norms and the required discharge (considering availability of electricity) and total design head along with all necessary accessories like cables, control panels, safety equipment, valves and fittings etc.,
                                        </p>
                                        <p className="body-heading-paragraph" >Based on the requirement of Pumps load and other accessories suitable step-down transformers shall be provided including all accessories and fittings with 50% standby (at least minimum one unit additional) arrangement. Suitable provision shall be made for extension of HT line from existing available point to intake site in consultation with Electricity Board. Provision for dedicated power feeder from nearby sub-station to intake site to incorporate in case of very large sized/mega projects and as per site.
                                            Design of pipes for conveying water by pumping by calculating the friction losses, if necessary and choosing suitable pipe materials considering corrosion resistance and design of valves such as reflux valves, kinetic air valves, non-return valves, scour valves etc., at necessary points.
                                            Design of Pipe carrying bridge by calculating the self-weight of pipe and internal liquid pressure subjected to transverse loading, designing of base slab, concrete girders, piers, abutments, and also providing coffer dams, if necessary.
                                        </p>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                        <img src={PipeLineDesignImage}  className="img-fluid float-right"  alt="PipeLineDesignImage"/>
                                </div>
                        </div>
                    </div>          
                </div>

            </div>

    </div>)


}

export default PipeLineDesign;