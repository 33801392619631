import React from 'react';

const initialState = {
    loading : true,
    data    : [],
    error   : ''
}
const reducer = (state=initialState,action)=>{

    switch(action.type){
        case 'WEBIMAGES':
            return{
                ...state,
                loading : false,
                data    : action.payload,
                error   : ''
            }
        case 'WEBERROR':
            return{
                loading : false,
                data    : '',
                error   : action.errorMsg
            }
        default : 
            return state
    }
}

export default reducer;