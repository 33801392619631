import React,{ useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { getWebImages } from './redux/action/WebAction';
import store from './store';
import Header from './component/layout/MainHeader';
import TopHeader from './component/layout/TopHeader';
import LoginHeader from './component/layout/LoginHeader';
import Footer from './component/layout/Footer';
import Home from './component/Home';

import MITankDesign from './component/dpr-prepartion/MITankDesign';
import PipeLineDesign from './component/dpr-prepartion/PipeLineDesign';
import RoadDesign from './component/dpr-prepartion/RoadDesign';
import TowerAlignmentDesign from './component/dpr-prepartion/TowerAlignmentDesign';
import TunnelDesign from './component/dpr-prepartion/TunnelDesign';
import Ugss from './component/dpr-prepartion/Ugss';

import AugerTractor from './component/equipment/AugerTractor';
import DroneSurveying from './component/equipment/DroneSurveying';
import GlobalPositionSystem from './component/equipment/GlobalPositionSystem';
import HandGPS from './component/equipment/HandGPS';
import SoilTesting from './component/equipment/SoilTesting';
import TotalStation from './component/equipment/TotalStation';


import CaliforniaBearingRatio from './component/soli-Test/CaliforniaBearingRatio';
import Consistency from './component/soli-Test/Consistency';
import Consolidation from './component/soli-Test/Consolidation';
import FieldDensity from './component/soli-Test/FieldDensity';
import GrainSizeAnalysis from './component/soli-Test/GrainSizeAnalysis';
import Permeability from './component/soli-Test/Permeability';
import ProctorCompaction from './component/soli-Test/ProctorCompaction';
import ShearTest from './component/soli-Test/ShearTest';
import StandardPenetration from './component/soli-Test/StandardPenetration';


import ContourSurveying from './component/survey/ContourSurveying';
import HighwaySurveying from './component/survey/HighwaySurveying';
import LandAcquisitionWork from './component/survey/LandAcquisitionWork';
import LandSurveying from './component/survey/LandSurveying';
import PipeLineSurveying from './component/survey/PipeLineSurveying';
import TunnelSurveying from './component/survey/TunnelSurveying';

// Front Page

import Contact from './component/Contact';
import Gallery from './component/Gallery';
import AboutUs from './component/AboutUs';

import Sample from './component/Sample';

//Login

// import FrontPage from './component/login/FrontPage';

import LoginContact from './component/login/LoginContact';

import LoginFooter from './component/login/LoginFooter';

import LoginGallery from './component/login/LoginGallery';

import Logout from './component/login/Logout';

//Pages
import FooterView from './pages/footer/FooterView';
import FooterInsert from './pages/footer/FooterInsert';

import ContactView from './pages/contact/ContactView';
import ContactInsert from './pages/contact/ContactInsert';

import GalleryView from './pages/gallery/GalleryView';
import GalleryInsert from './pages/gallery/GalleryInsert';

import FrontPageView from './pages/front/FrontPageView';
import FrontPage from './pages/front/FrontPageUpdate';

import FrontContentViewPage from './pages/front/content/FrontContentView';
import FrontContentUpdate from './pages/front/content/FrontContentUpdate';

import ScrollPageView from './pages/front/scroll/ScrollPageView';
import ScrollPageInsert from './pages/front/scroll/ScrollPageInsert';

import  Pagination from './pages/common/Pagination';


function App() {

  // useEffect(()=>{

  //   store.dispatch(getWebImages())

  // },[]) 
 

  return (
    <Router>
      <Provider store={ store } >
      <div className="App">  
      <TopHeader />     
       <Header />
       <Routes> 
          <Route path="/" exact={true} element={<Home />} />              
          <Route path="/Contact" element={<Contact />} />       
          <Route path="/Gallery" element={<Gallery />} />  
          <Route path="/AboutUs" element={<AboutUs />} />  
          {/* dpr-prepartion */}
          <Route path="/MITankDesign" element={<MITankDesign />} />       
          <Route path="/PipeLineDesign" element={<PipeLineDesign />} />       
          <Route path="/RoadDesign" element={<RoadDesign />} />       
          <Route path="/TowerAlignmentDesign" element={<TowerAlignmentDesign />} />       
          <Route path="/TunnelDesign" element={<TunnelDesign />} />       
          <Route path="/Ugss" element={<Ugss />} />      
          {/* equipment */}
          <Route path="/AugerTractor" element={<AugerTractor/>} />       
          <Route path="/DroneSurveying" element={<DroneSurveying/>} />       
          <Route path="/GlobalPositionSystem" element={<GlobalPositionSystem/>} />       
          <Route path="/HandGPS" element={<HandGPS/>} />       
          <Route path="/SoilTesting" element={<SoilTesting/>} />       
          <Route path="/TotalStation" element={<TotalStation/>} />  
          {/* soli-Test */}
          <Route path="/CaliforniaBearingRatio" element={<CaliforniaBearingRatio /> } />       
          <Route path="/Consistency" element={<Consistency /> } />       
          <Route path="/Consolidation" element={<Consolidation /> } />       
          <Route path="/FieldDensity" element={<FieldDensity /> } />       
          <Route path="/GrainSizeAnalysis" element={<GrainSizeAnalysis /> } />       
          <Route path="/Permeability" element={<Permeability /> } />       
          <Route path="/ProctorCompaction" element={<ProctorCompaction /> } />       
          <Route path="/ShearTest" element={<ShearTest /> } />       
          <Route path="/StandardPenetration" element={<StandardPenetration /> } />
          {/* survey */}
          <Route path="/ContourSurveying" element={<ContourSurveying />} />       
          <Route path="/HighwaySurveying" element={<HighwaySurveying />} />       
          <Route path="/LandAcquisitionWork" element={<LandAcquisitionWork />} />       
          <Route path="/LandSurveying" element={<LandSurveying />} />       
          <Route path="/PipeLineSurveying" element={<PipeLineSurveying />} />       
          <Route path="/TunnelSurveying" element={<TunnelSurveying />} />    
          { /* Login */ }
          {/* <Route path="/FrontPage" element={FrontPage} /> */}

          { /* Layout */  }
          <Route path="/LoginFooter" element={<LoginFooter />} />
          <Route path="/LoginContact" element={<LoginContact />} />
          <Route path="/FrontPage" element={<FrontPage />} />
          <Route path="/LoginGallery" element={<LoginGallery />} />
          <Route path="/Logout" element={<Logout />} />

          <Route path="/FooterView" element={<FooterView />} />
          <Route path="/FooterInsert" element={<FooterInsert />} />

          <Route path="/ContactView" element={<ContactView />} />
          <Route path="/ContactInsert" element={<ContactInsert />} />

          <Route path="/GalleryView" element={<GalleryView />} />
          <Route path="/GalleryInsert" element={<GalleryInsert />} />

          <Route path="/FrontPageView" element={<FrontPageView />} />
          <Route path="/FrontPage" element={<FrontPage />} />

          <Route path="/FrontContentView" element={<FrontContentViewPage />} />
          <Route path="/FrontContentUpdate" element={<FrontContentUpdate />} />

          <Route path="/ScrollPageView" element={<ScrollPageView />} />
          <Route path="/ScrollPageInsert" element={<ScrollPageInsert />} />

        </Routes> 
      <Footer />    
     
    </div>
      </Provider>   
    </Router>
   
  );
}


export default App;
