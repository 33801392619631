import React from 'react';

import StandardPenetrationImage from '../../asset/image/category/StandardPenetration.jpg' ;

import InnerImage from '../InnerImage';


const StandardPenetration =()=>{

    return(<div>
        <InnerImage name="Standard Penetration Test"/>
                <div className="container">    
                    <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                    <p className="body-heading-paragraph">The standard penetration test (SPT) is an in-situ dynamic penetration test designed to provide
                                    information on the geotechnical engineering properties of soil. This test is the most frequently used subsurface exploration drilling
                                    test performed worldwide. The test procedure is described in ISO 22476-3, ASTM D1586[1] and Australian Standards AS 1289.6.3.1.
                                    The test provides samples for identification purposes and provides a measure of penetration resistance which can be used for
                                        geotechnical design purposes. Many local and widely published international correlations which relate blow count, or N-value,
                                        to the engineering properties of soils are available for geotechnical engineering purposes.</p>
                                        <ul className="background-non-style">
                                            <li> 1 &nbsp;&nbsp;&nbsp;Procedure.</li>
                                            <li> 2 &nbsp;&nbsp;&nbsp;Purpose. </li>                          
                                        </ul>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                    <img src={StandardPenetrationImage}  className="img-fluid float-right" alt="StandardPenetrationImage"/>
                            </div>
                            <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <h4 className="body-heading-title"> Procedure </h4><br />
                                                <p className="body-heading-paragraph">The test uses a thick-walled sample tube, with an outside diameter of 50.8 mm and an inside diameter
                                                of 35 mm, and a length of around 650 mm. This is driven into the ground at the bottom of a borehole by blows from a slide hammer with
                                                a mass of 63.5 kg (140 lb) falling through a distance of 760 mm (30 in). The sample tube is driven 150 mm into the ground and then the
                                                number of blows needed for the tube to penetrate each 150 mm (6 in) up to a depth of 450 mm (18 in) is recorded. The sum of the number
                                                    of blows required for the second and third 6 in. of penetration is termed the "standard penetration resistance" or the "N-value". 
                                                    In cases where 50 blows are insufficient to advance it through a 150 mm (6 in) interval the penetration after 50 blows is recorded.
                                                    The blow count provides an indication of the density of the ground, and it is used in many empirical geotechnical engineering formula..</p>

                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                            <th scope="col">Relative density</th>
                                                            <th scope="col">SPT N value</th>
                                                            <th scope="col">Bulk density (kg/m³)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Very loose</td>
                                                                <td>0 - 4</td>
                                                                <td> 0 &#60; 1600</td>
                                                            </tr>
                                                            <tr>                                    
                                                                <td>Loose</td>
                                                                <td>5 - 10</td>
                                                                <td>1530 - 2000</td>
                                                            </tr>
                                                            <tr>   
                                                                <td>Medium</td>
                                                                <td>11 - 30</td>
                                                                <td>1750 - 2100</td>
                                                            </tr>
                                                            <tr>   
                                                                <td>Dense</td>
                                                                <td>31 - 50</td>
                                                                <td>1750 - 2245</td>
                                                            </tr>
                                                            <tr>   
                                                                <td>Very dense</td>
                                                                <td>0 &#62; 50</td>
                                                                <td>0 &#62; 2100</td>
                                                            </tr>
                                                        </tbody>
                                                        </table>
                                                        <h4 className="body-heading-title"> Purpose </h4><br />

                                                        <p className="body-heading-paragraph">The main purpose of the test is to provide an indication of the relative density of granular deposits, such as sands and gravels from which it is virtually impossible to obtain undisturbed samples. The great merit of the test, and the main reason for its widespread use, is that it is simple and inexpensive. The soil strength parameters which can be inferred are approximate, but may give a useful guide in ground conditions where it may not be possible to obtain borehole samples of adequate quality like gravels, sands, silts, clay containing sand or gravel and weak rock. In conditions where the quality of the undisturbed sample is suspect, e.g., very silty or very sandy clay, or hard clay, it is often advantageous to alternate the sampling with standard penetration tests to check the strength. If the samples are found to be unacceptably disturbed, it may be necessary to use a different method for measuring strength like the plate test. When the test is carried out in granular soils below groundwater level, the soil may become loosened. In certain circumstances, it can be useful to continue driving the sampler beyond the distance specified, adding further drilling rods as necessary. Although this is not a standard penetration test, and should not be regarded as such, it may at least give an indication as to whether the deposit is really as loose as the standard test may indicate.
                                                            The usefulness of SPT results depends on the soil type, with fine-grained sands giving the most useful results, with coarser sands and silty sands giving reasonably useful results, and clay and gravelly soils yielding results which may be very poorly representative of the true soil conditions. Soils in arid areas, such as the Western United States, may exhibit natural cementation. This condition will often increase the standard penetration value.
                                                            The standard penetration test recovers a highly disturbed sample, which is generally not suitable for tests which measure properties of the in-situ soil structure, such as density, strength, and consolidation characteristics. To overcome this limitation, the test is often run with a larger sampler with a slightly different tip shape, so the disturbance of the sample is minimized, and testing of structural properties is meaningful for all but soft soils. However, this results in blow counts which are not easily converted to SPT N-values – many conversions have been proposed, some of which depend on the type of soil sampled, making reliance on blow counts with non-standard samplers problematic.
                                                            Standard penetration test blow counts do not represent a simple physical property of the soil, and thus must be correlated to soil properties of interest, such as strength or density. There exist multiple correlations, none of which are of very high quality.[3] Use of SPT data for direct prediction of liquefaction potential suffers from roughness of correlations and from the need to "normalize" SPT data to account for overburden pressure, sampling technique, and other factors. Additionally, the method cannot collect accurate data for weak soil layers for several reasons:
                                                            The results are limited to whole numbers for a specific driving interval, but with very low blow counts, the granularity of the results, and the possibility of a zero result, makes handling the data cumbersome.
                                                            In loose sands and very soft clays, the act of driving the sampler will significantly disturb the soil, including by soil liquefaction of loose sands, giving results based on the disturbed soil properties rather than the intact soil properties.
                                                            A variety of techniques have been proposed to compensate for the deficiencies of the standard penetration test, including the Cone penetration test, in-situ vane shear tests, and shear wave velocity measurements.
                                                        </p>
                                        </div>
                            </div>
                        </div>
                </div>     
    </div>)

}

export default StandardPenetration;