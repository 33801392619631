import React,{ Fragment,useState,useEffect } from 'react';

import { connect } from 'react-redux';

import { WebContactInsert,getWebContact } from '../../redux/action/WebContactAction';

import PropsType from 'prop-types';


const LoginContact =({ WebContactInsert,getWebContact,getStatus })=>{    

    const [formData,setFormData] = useState({
        "action" : "Insert",
        "RfId" : "",
        "Address" : "",
        "TelePhoneNo" : "",
        "MobileNo" : "",
        "EmailId" : "",
        "Remarks" : ""
    })

    useEffect(()=>{
        getWebContact()
        console.log(getStatus.data)
        var datas = getStatus.data
       
        if (getStatus.message == "Success") {
            // console.log(datas);
            datas.map(data=>{
                setFormData({
                    "Address" : data.Address,
                    "TelePhoneNo" : data.TelePhoneNo,
                    "MobileNo" : data.MobileNo,
                    "EmailId" : data.EmailId,
                    "Remarks" : data.Remarks,
                    "RfId" : data.RfId
                })
            })
        }

    },[])

    const onChangeset=(e)=> setFormData({
        ...formData,
        [e.target.name] : e.target.value

    }) 

    const submitHandler=(e)=>{
        e.preventDefault();
        // console.log(formData);
        WebContactInsert(formData);      
        // console.log(getStatus);

    }
    return(<Fragment>
        <h1> Login Contact </h1>
        <hr />
           <div className="container">
               <div className="row">
                   <div className="col-md-12">
                        <form onSubmit={ submitHandler }  className="was-validated">
                            <div className="form-group">
                                <label for="uname">TelePhone Number :</label>
                                <input type="text" className="form-control" value={ formData.TelePhoneNo }  placeholder="Enter Telephone Number" name="TelePhoneNo" id="TelePhoneNo" onChange={ (e)=>onChangeset(e) } />
                            </div>
                            <div className="form-group">
                                <label for="uname">Mobile No :</label>
                                <input type="text" className="form-control" value={ formData.MobileNo } placeholder="Enter Mobile No" name="MobileNo" id="MobileNo" onChange={ (e)=>onChangeset(e) }  />
                            </div>
                            <div className="form-group">
                                <label for="uname">Email-Id :</label>
                                <input type="text" className="form-control" value ={ formData.EmailId }  placeholder="Enter Email - Id" name="EmailId" id="EmailId" onChange={ (e)=>onChangeset(e) }  />
                            </div>
                            <div className="form-group">
                                <label for="uname">Address :</label>
                                <textarea  className="form-control" value={ formData.Address }  onChange={ (e)=>onChangeset(e) }   name="Address" id="Address">  </textarea>
                            </div>
                            <div className="form-group">
                                <label for="uname">Remarks :</label>
                                <textarea  className="form-control" value={ formData.Remarks }  onChange={ (e)=>onChangeset(e) }  name="Remarks" id="Remarks">  </textarea>
                            </div>                                               
                            <button type="submit" className="btn btn-info"> Update </button>
                        </form>
                   </div>
               </div>
           </div>
    </Fragment>)
}

PropsType.LoginContact={
    WebContactInsert : PropsType.func.isRequired,
    getWebContact    : PropsType.func.isRequired
}

const mapStateToProps=(state)=>({
    getStatus : state.WebContactReducer.data
})

export default connect(mapStateToProps,{WebContactInsert,getWebContact}) (LoginContact)