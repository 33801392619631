import React, { Fragment } from 'react';
import Logo from '../../asset/image/logo.jpeg';
import Ragu from '../../asset/image/ragu.jpg';
import Kural from '../../asset/image/kural.jpeg';
import SaveWater from '../../asset/image/save-water.jpg';
import useFrontPage from '../../pages/common/useFrontPage';
const TopHeaderChild = ()=>{

    // console.log(props)

    const [frontView, frontViewError ] = useFrontPage();

    function formatDate(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return (date.getMonth()+1) + "-" + date.getDate() + "-" + date.getFullYear() + "  " + strTime;
      }
      
      var d = new Date();
      var e = formatDate(d);

    // const state = {date: new Date()}

    return(<Fragment>
        {
            frontView.map((data,index)=>{
                return(<div className="container-fluid">
                    <div className="row" key={ data.RfId ? data.RfId : 1 } >
                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4" >
                            <img src={ data.LogoImage ? data.LogoImage : Logo } className="img-fluid float-left mx-auto d-block logo-image" alt="Save Water" />                   
                        </div>
                        <div className="col-lg-1 col-xl-1"></div>
                        {/* <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3"  >
                                   <div className="row">
                               
                                        <img src={  SaveWater } className="img-fluid float-left water-image" height="100" width="100"  alt="Src"/>
                                   <div>
                                    <p className="quoates-front-page" >" நீர் ஆதாரத்தை பாதுகாப்போம் </p>
                                    <p className="quoates-front-page-second" > நீர் வாளத்தை பெருக்குவோம் "</p>
                                   </div>                                   
                            </div>
                        </div> */}
                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4"  >                   
                                <img src={  data.KuralImage ? data.KuralImage :  Kural } className="img-thumbnail kural-image"  alt="Ragu" />
                        </div>
                        <div className="col-lg-1 col-xl-1"></div>
                        <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                            <img src={  data.ProfileImage ? data.ProfileImage : Ragu } className="img-thumbnail"  alt="Src" width="180" />  
                            <p>{e}</p>                              
                            {/* <p> { new Date().toLocaleDateString() } &nbsp;<Clock format={'hh-mm'} /> </p>  <p>{e}</p> */}
                        </div>

                </div>
                </div>)
            })
        }
       
    </Fragment>);

}

  export default TopHeaderChild;