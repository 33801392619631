import React from "react";
import { useNavigate } from "react-router-dom";
import { createTheme   } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import useContact from '../common/useContact';
import Loader from '../loader/Loader';

const useStyles = createTheme  ({
  table: {
    minWidth: 600,
    border: "2px solid blue",
    boxShadow: "5px 10px #999999",
  },
});



const ContactView = () => {
  const classes = useStyles();

  const history = useNavigate();

  const [footerView, footerError ] = useContact();

  const contactMove = () =>{

    history.push("/ContactInsert");       
}
 
  return (<React.Fragment>
    <Loader active={ footerView.length ? false : true } />
      <Grid
      item
      container
      xs={12}
      sm={12}
      lg={12}
      md={12}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid lg={2} md={12}></Grid>

      <Grid
        item
        container
        xs={12}
        lg={8}
        md={8}
        spacing={2}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="caption table">
            <caption>SRC CONTACT VIEW </caption>
            <TableHead>
              <TableRow>
                <TableCell align="right">SNo</TableCell>
                <TableCell align="right">Mobile No</TableCell>
                <TableCell align="right">TelePhone No</TableCell>
                <TableCell align="right">Address</TableCell>
                <TableCell align="right">EmailId</TableCell>
                <TableCell align="right">Update</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {footerView.map((footer) => (
                <TableRow key={footer.RfId}>
                  <TableCell align="right">{footer.RfId}</TableCell>
                  <TableCell align="right">
                    {footer.MobileNo.split("<br />").map((i, key) => {
                      return <p key={key}>{i} </p>;
                    })}{" "}
                  </TableCell>
                  <TableCell align="right">{footer.TelePhoneNo}</TableCell>
                  <TableCell align="right">
                    {footer.Address.split("<br />").map((i, key) => {
                      return <p key={key}>{i} </p>;
                    })}{" "}
                  </TableCell>
                  <TableCell align="right">{footer.EmailId}</TableCell>
                  <TableCell align="right">
                    <Button variant="contained" color="primary" onClick={ contactMove } >
                       Update
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={2} md={12}></Grid>
    </Grid>
  </React.Fragment>
  );
};

export default React.memo(ContactView);
